import React from "react";
import { Route, RouteComponentProps, RouteProps } from "react-router-dom";
import SideMenu from "./components/side-menu/sidemenu";
export default function CustomRoute({ component: Component, ...rest }) {
  console.log(Component);
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>{Component && <Component {...props} />}</Layout>
      )}
    />
  );
}

function Layout({ children }) {
  return (
    <React.Fragment>
      <SideMenu />
      <div id="mainContent">
        <div className="inner-wrapper">{children}</div>
      </div>
    </React.Fragment>
  );
}

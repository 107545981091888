import React, { Component } from 'react';

import './users.css';
import axios from 'axios';

import SideMenu from '../../components/side-menu/sidemenu';
import JSONData from '../../json/data.json';

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mail: ""
        }
        this.createList = this.createList.bind(this);
        this.add = this.add.bind(this);
    }

    createList = (e) => {
        this.setState({ mail: e.target.value });

    }

    add = (e) => {
        e.preventDefault();
        var mailStr = this.state.mail;
        mailStr = mailStr.replace(/ /g, '');
        var mailList = mailStr.split(',');
        const token = localStorage.getItem('access')

        console.log(mailList);

        const data = {
            // "custid": localStorage.getItem('custid'), //to be changed
            "email_list": mailList
        }

        axios(JSONData.invoice_api_url + '/users/invite/', {
            method: 'POST',
            data: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            crossdomain: true
        }).then(res => {
            // console.log(res.data);



            if (res.status === 200) {
                

            }
            else if(res.status === 201) {

                alert(res.data.message)

            } else {
                
            }


        }).catch(error => {
          
            

            if (error.response) {

                if (error.response.data.message === "Token is invalid!" || error.response.data.message === "Not Authorized!") {
                    alert(error.response.data.message);
                    this.setState({ tokenRed: true });
                }
                else {
                    alert(JSON.stringify(error.response.data))
                }

                console.log("error.response.data", error.response.data);
                console.log("error.response.status", error.response.status);
                console.log("error.response.headers", error.response.headers);

                

            } else if (error.request) {
                
                console.log(error.request);

            } else {
                
                console.log('Error', error.message);
            }
            console.log(error.config);

           
        })
    }

    render() {

        return (
            <div className="main">

                <SideMenu highlight="user" />

                <div id="users" className="wrapper">
                    <div id="usersHead" className="row">
                        <h5>Add Staff</h5>
                    </div>

                    <div id="usersMain" className="row">
                        <div id="addUserDiv" className="row">

                            <div id="addUserLabel" className="row">
                                <h6>Add Staff</h6>
                            </div>
                            <div className="row" style={{ width: "100%" }}>
                                <input name="uname" className="addUsertextInput" onChange={this.createList} type="text" placeholder="" />
                                <button type="submit" id="addUserButton" onClick={this.add}>Add</button>
                            </div>
                            <div id="addUserNote" className="row">
                                <p>
                                    For multiple user IDs, use comma to seperate each entry. For example: user1@example.com, user2@example.com.
                                </p>

                            </div>



                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default Users;
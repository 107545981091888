import React, { Component } from 'react';
import './register.css';
import 'react-phone-input-2/lib/style.css';
import { NavLink } from "react-router-dom";

class Register extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="registerMsg">
                        <div className="mainLogo2"></div>
                        <h1>Authentication and Authorization</h1>


                        <div class="alert alert-danger" role="alert">
                            <div className="container text-center">
                                Something went wrong, user registration failed.
                    </div>
                        </div>
                        <NavLink to="/Register" style={{ textDecoration: 'none' }}><button id="createButton">Create your IN-D Account</button></NavLink>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Register;
import React, { Component } from "react";
import "../side-menu/side-menu.css";
import "../../Views/products/products.css";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Logout } from "../../Actions/userdetailsaction";
import { connect } from "react-redux";
class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { showModal: false };
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.logout = this.logout.bind(this);
  }
  componentDidMount() {
    console.log(this.props.highlight);
    if (this.props.highlight === "prod") {
      $("#sideElements1").addClass("backColor");
    } else if (this.props.highlight === "bill") {
      $("#sideElements2").addClass("backColor");
    } else if (this.props.highlight === "user") {
      $("#sideElements3").addClass("backColor");
    }
  }
  open() {
    this.setState({ showModal: true });
  }
  close() {
    this.setState({ showModal: false });
  }
  logout() {
    this.props.Logout();
    localStorage.clear();
    window.location.href = "/";
  }
  render() {
    return (
      <div>
        <nav id="sidebar" className="activexyz">
          <ul id="sideNavTop" className="list-unstyled components">
            <li id="logoLi">
              <div id="">
                <div className="activexyz indlogo"></div>
              </div>
            </li>
            {/* <ReactTooltip place="right" type="dark" effect="solid" style={{overflow: "visible"}} /> */}
            <Tooltip title="Products" placement="right">
              <li>
                <Link to="/Products" style={{ textDecoration: "none" }}>
                  <div data-tip="Dashboard" id="sideElements1" className="">
                    <div className="activexyz Dashboard">
                      <p> Dashboard </p>
                    </div>
                  </div>
                </Link>
              </li>
            </Tooltip>
            <Tooltip title="Usage History" placement="right">
              <li>
                <Link to="/Billing" style={{ textDecoration: "none" }}>
                  <div id="sideElements2" className="">
                    <div className="activexyz Settings">
                      <p> Settings </p>
                    </div>
                  </div>
                </Link>
              </li>
            </Tooltip>
            <Tooltip title="Users" placement="right">
              <li>
                <Link to="/Users" style={{ textDecoration: "none" }}>
                  <div id="sideElements3" className="">
                    <div className="activexyz Users">
                      <p> Users </p>
                    </div>
                  </div>
                </Link>
              </li>
            </Tooltip>
            <Tooltip title="Profile" placement="right">
              <li>
                <Link to="/Profile" style={{ textDecoration: "none" }}>
                  <div id="sideElements4" className="">
                    <div className="activexyz Profile">
                      <p> Profile </p>
                    </div>
                  </div>
                </Link>
              </li>
            </Tooltip>
          </ul>
          <ul id="sideNavBottom" className="list-unstyled components">
            {/* <Tooltip title="Information" placement="right"> <li> <div id="sideElements4" className=""> <div className="activexyz Information" > <p> Information </p> </div> </div> </li> </Tooltip> */}
            <Tooltip id="tool" title="Logout" placement="right">
              <li>
                <div id="sideElements" onClick={this.open}>
                  <div className="activexyz Logout">
                    <p> Logout </p>
                  </div>
                </div>
                <Modal
                  className="logoutModal"
                  show={this.state.showModal}
                  onHide={this.close}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to logout?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="row  justify-content-center">
                    <Button id="logoutButton" onClick={this.logout}>
                      Yes
                    </Button>
                    <Button id="logoutButton" onClick={this.close}>
                      No
                    </Button>
                  </Modal.Body>
                </Modal>
              </li>
            </Tooltip>
          </ul>
        </nav>

        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <a href="/Products" className="activexyz logo navbar-brand"></a>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav">
              <Tooltip title="Products" placement="right">
                <li>
                  <Link to="/Products" style={{ textDecoration: "none" }}>
                    <div data-tip="Dashboard" id="sideElements1" className="">
                      <div className="activexyz Dashboard">
                        <p> Dashboard </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title="Usage History" placement="right">
                <li>
                  <Link to="/Billing" style={{ textDecoration: "none" }}>
                    <div id="sideElements2" className="">
                      <div className="activexyz Settings">
                        <p> Settings </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title="Users" placement="right">
                <li>
                  <Link to="/Users" style={{ textDecoration: "none" }}>
                    <div id="sideElements3" className="">
                      <div className="activexyz Users">
                        <p> Users </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip title="Profile" placement="right">
                <li>
                  <Link to="/Profile" style={{ textDecoration: "none" }}>
                    <div id="sideElements3" className="">
                      <div className="activexyz Profile">
                        <p> Profile </p>
                      </div>
                    </div>
                  </Link>
                </li>
              </Tooltip>
              <Tooltip id="tool" title="Logout" placement="right">
                <li>
                  <div id="sideElements" onClick={this.open}>
                    <div className="activexyz Logout">
                      <p> Logout </p>
                    </div>
                  </div>
                  <Modal
                    className="logoutModal"
                    show={this.state.showModal}
                    onHide={this.close}
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        Are you sure you want to logout?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="row  justify-content-center">
                      <Button id="logoutButton" onClick={this.logout}>
                        Yes
                      </Button>
                      <Button id="logoutButton" onClick={this.close}>
                        No
                      </Button>
                    </Modal.Body>
                  </Modal>
                </li>
              </Tooltip>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}
const mapDispatchToProps = {
  Logout,
};
export default connect(() => ({}), mapDispatchToProps)(SideMenu);

import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import "./PasswordReset.css";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  PasswordUpdate,
  ConfirmeResetPassword,
  userLogin,
} from "../../Actions/userdetailsaction";
import password_hide from "../../images/password_hide.png";
import password_show from "../../images/password_show.png";
import { useDispatch, useSelector } from "react-redux";
const PasswordReset = (props) => {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [Password, setPassword] = useState({
    old_password: "",
    password: "",
    confirmed_password: "",
  });
  let user_token = localStorage.getItem("access");
  const [score, setScore] = useState(0);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [resetPasswordToken, setResetPasswordToken] = useState("");
  const [passwordNotMatch, setPasswordNotMatch] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hidden, setHidden] = useState(true);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (Password.password !== Password.confirmed_password) {
      setPasswordNotMatch(true);
    } else {
      setPasswordNotMatch(false);
    }
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return setValidated(true);
    } else if (
      Password.password === Password.confirmed_password &&
      resetPasswordToken
    ) {
      setPasswordNotMatch(false);
      dispatch(
        ConfirmeResetPassword(Password, resetPasswordToken, props.history)
      );
    } else if (user_token) {
      dispatch(PasswordUpdate(Password));
    }
  };

  const validate = (e) => {
    let password = e.target.value;
    let errorMessage;
    if (password.length <= 0) {
      setScore(0);
    }
    let capsCount, smallCount, numberCount, symbolCount;
    if (password.length < 8) {
      setErrorMessage("Password should be in 8 Characters!..");
    } else {
      capsCount = (password.match(/[A-Z]/g) || []).length;
      smallCount = (password.match(/[a-z]/g) || []).length;
      numberCount = (password.match(/[0-9]/g) || []).length;
      symbolCount = (password.match(/\W/g) || []).length;
      if (capsCount < 1) {
        errorMessage = "Must contain Caps!..";
      } else if (smallCount < 1) {
        errorMessage = "Must contain small letters!..";
      } else if (numberCount < 1) {
        errorMessage = "Must contain Number!..";
      } else if (symbolCount < 1) {
        errorMessage = "Must contain Symbol!..";
      }
      setErrorMessage(errorMessage);
      measurePasswordStrength(password);
    }
  };
  const measurePasswordStrength = (password) => {
    let s = 0;
    let passwordStrength;
    let regexPositive = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
    regexPositive.forEach((regex, index) => {
      if (new RegExp(regex).test(password)) {
        s += 1;
      }
    });

    setScore(s);
    switch (s) {
      case 0:
        passwordStrength = "too weak";
      case 1:
        passwordStrength = "weak";
        break;
      case 2:
      case 3:
        passwordStrength = "good";
        break;
      case 4:
      case 5:
        passwordStrength = "strong";
        break;
    }
    setPasswordStrength(passwordStrength);
  };
  const toggleShow = () => {
    setHidden(!hidden);
  };
  const handleChange = (event) => {
    setPassword({
      ...Password,
      [event.target.name]: event.target.value,
    });
    console.log(Password);
  };
  const style = {
    marginTop: "10%",
    color: "white",
  };
  useEffect(() => {
    const urlElements = window.location;
    var url = new URL(urlElements);
    let token = url.searchParams.get("token");
    setResetPasswordToken(token);
    console.log(token);
  }, []);
  console.log(user_token, resetPasswordToken);
  return (
    <React.Fragment>
      {!localStorage.getItem("access") ? (
        <div>
          <div className="loginHead">
            <div className="mainLogo"></div>
            <div className="logintext">Authentication and Authorization</div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="passwordHead col-md-4 col-sm-12">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          {!resetPasswordToken ? (
            <Form.Group as={Row} controlId="formPlaintextModelName">
              <Form.Label column sm="12">
                Old Password
              </Form.Label>
              <Col sm="12">
                <Form.Control
                  type="text"
                  value={Password.old_password}
                  onChange={handleChange}
                  name="old_password"
                  required
                />
                <Form.Control.Feedback type="invalid" className="error">
                  Change Password field is required.
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          ) : (
            ""
          )}
          <Form.Group as={Row} controlId="formSelectCountry">
            <Form.Label column sm="12">
              New Password
            </Form.Label>
            <Col sm="12">
              <Form.Control
                type="text"
                name="password"
                value={Password.password}
                onChange={(e) => {
                  handleChange(e);
                  validate(e);
                }}
                required
              ></Form.Control>
              <div className="password-strength">
                <div className="password-strength-text">Password Strength</div>
                {/* <progress max='100' value='30' /> */}
                <LinearProgress
                  max={100}
                  value={
                    score <= 0
                      ? 0
                      : score <= 2
                      ? 30
                      : score < 4 && score > 2
                      ? 60
                      : 100
                  }
                  variant="determinate"
                  className="linear-progress"
                />
              </div>
              <Form.Control.Feedback type="invalid" className="error">
                New Password field is required.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formPlaintextDocName">
            <Form.Label column sm="12">
              Confirm Password
            </Form.Label>
            <Col sm="12">
              <Form.Control
                type={hidden ? "password" : "text"}
                name="confirmed_password"
                value={Password.confirmed_password}
                onChange={handleChange}
                required
              />
              <div onClick={toggleShow} className="hide_div">
                {hidden ? (
                  <img
                    src={password_hide}
                    alt="hide"
                    className="hide"
                    style={{ marginTop: "-32px" }}
                  />
                ) : (
                  <img
                    src={password_show}
                    alt="hide"
                    className="hide"
                    style={{ marginTop: "-32px" }}
                  />
                )}
              </div>
              <Form.Control.Feedback type="invalid" className="error">
                Confirm Password field is required.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          {errorMessage ? <div className="error">{errorMessage}</div> : ""}
          {passwordNotMatch ? (
            <div className="error">
              Password and Confirm Password must be same!.
            </div>
          ) : (
            ""
          )}
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={score >= 4 ? false : true}
          >
            Reset Password
          </Button>
        </Form>
      </div>
    </React.Fragment>
  );
};
export default PasswordReset;

import React, { Component } from 'react';
import axios from 'axios';
import SideMenu from '../../components/side-menu/sidemenu';
import './products.css';
import JSONData from '../../json/data.json';
import { Modal } from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
// import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import MUIDataTable from 'mui-datatables';
import { Redirect } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import KYClogo from '../../images/logoV.png';
import Creditslogo from '../../images/logoC.png';
import Payableslogo from '../../images/logoP.png';
import Receiptslogo from '../../images/logoR.png';
import Insurancelogo from '../../images/logoI.png';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showUploadModal: false,
      toReview_redirect: false,
      reviewed_redirect: false,
      dash_result: '',
      cur_date: '',
      files: [],
      job_id: '',
      document_name: '',
      batchName: '',
      selected_id: '',
      selected_batch: '',
      val_redirect: false,
      dash_redirect: false,
      dig_flag: false,
      tokenRed: false,
      data: [],
      serviceList: null,
      applicationName: '',
      platform: '',
      service: '',
      key: null,
      copied: null,
      expiry: '',
      name: '',
      expiryError: false,
      serverData: [],
      applicationData: [],
      dummy: [],
      product_name: '',
    };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);

    this.handleApplicationNameChange =
      this.handleApplicationNameChange.bind(this);
    this.handlePlatformChange = this.handlePlatformChange.bind(this);
    this.handleServiceChange = this.handleServiceChange.bind(this);
    this.onCreateAnApp = this.onCreateAnApp.bind(this);
  }

  handleApplicationNameChange = (e) => {
    this.setState({ applicationName: e.target.value });
  };

  handlePlatformChange = (e) => {
    console.log('handlePlatformChange', e.target.value);
    this.setState({ platform: e.target.value });
  };

  handleServiceChange = (e) => {
    console.log('Asdasdasd', e.target.value);
    this.setState({ service: e.target.value });
  };

  close() {
    this.setState({ showModal: false });
  }

  open(data) {
    console.log(data);
    this.setState({ showModal: true, service: data });
  }

  mailClose() {
    this.setState({ mailsend: false });
  }

  mailOpen(data) {
    console.log(data);
    this.setState({ mailsend: true, id: data });
  }

  copied(data) {
    console.log(data);
    // alert("Hi")
    this.setState({ copied: true });

    const action = (key) => (
      <React.Fragment>
        <Button
          onClick={() => {
            this.props.closeSnackbar(key);
          }}
        >
          Dismiss
        </Button>
      </React.Fragment>
    );

    this.props.enqueueSnackbar('Key ' + data + ' Copied', {
      variant: 'success',
      autoHideDuration: 3000,
      action,
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.getDatafromApi();
    const token = localStorage.getItem('access');
    if (token !== null) {
      const serviceList = [
        { id: 'KYC', name: 'KYC', url: 'https://vkyc.in-d.ai/?token=' + token },
        {
          id: 'Credit',
          name: 'Credit',
          url: 'https://onesit.in-d.ai/bankstatement?token=' + token,
        },
        {
          id: 'Payables',
          name: 'Payables',
          url: 'http://payables.in-d.ai?token=' + token,
        },
        {
          id: 'Billspls',
          name: 'Billspls',
          url: 'http://receipts.in-d.ai?token=' + token,
        },
        {
          id: 'Insurance',
          name: 'Insurance',
          url: 'http://13.92.226.228?token=' + token,
        },
      ];

      this.setState({ serviceList: serviceList });

      // this.getAllservices();
      // this.getAllApplications();
    } else {
      this.props.history.push('/');
    }
  }
  getDatafromApi = () => {
    const token = localStorage.getItem('access');
    axios('https://onesit.in-d.ai/api/applications/', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      for (let i = 0; i < res.data.length; i++) {
        const data = {
          id: res.data[i].id,
          name: res.data[i].name,
          user: res.data[i].user,
          channel: res.data[i].channel,
          key: res.data[i].key,
          // expiry: res.data[i].expiry.slice(0, 19),
        };
        this.state.dummy.push(data);
      }
      this.setState({
        applicationData: this.state.dummy,
      });
    });
  };
  getAllApplications() {
    const token = localStorage.getItem('access');
    const data = {
      token: token,
    };
    console.log(data);
    axios(JSONData.invoice_api_url + '/applications/', {
      method: 'GET',
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        console.log('applications List', res);

        if (res.status === 201) {
          this.setState({ data: res.data });

          const action = (key) => (
            <React.Fragment>
              <Button
                onClick={() => {
                  this.props.closeSnackbar(key);
                }}
              >
                Dismiss
              </Button>
            </React.Fragment>
          );
          const message = 'Application list loaded';
          this.props.enqueueSnackbar(message, {
            variant: 'info',
            autoHideDuration: 3000,
            action,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('error.response.data', error.response.data);
          console.log('error.response.status', error.response.status);
          console.log('error.response.headers', error.response.headers);
          // alert(JSON.stringify(error.response.data))
          const action = (key) => (
            <React.Fragment>
              <Button
                onClick={() => {
                  this.props.closeSnackbar(key);
                }}
              >
                Dismiss
              </Button>
            </React.Fragment>
          );

          for (const [key, value] of Object.entries(error.response.data)) {
            console.log('key', key);
            this.props.enqueueSnackbar(key + ' - ' + value, {
              variant: 'error',
              autoHideDuration: 3000,
              action,
            });
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  getAllservices() {
    const token = localStorage.getItem('access');
    const data = {
      token: token,
    };
    console.log(data);
    axios(JSONData.invoice_api_url + '/services/', {
      method: 'GET',
      data: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        console.log('services List', res);

        if (res.status === 200) {
          console.log(res.data);
          this.setState({ serviceList: res.data });

          const action = (key) => (
            <React.Fragment>
              <Button
                onClick={() => {
                  this.props.closeSnackbar(key);
                }}
              >
                Dismiss
              </Button>
            </React.Fragment>
          );
          const message = 'Service list loaded';
          this.props.enqueueSnackbar(message, {
            variant: 'info',
            autoHideDuration: 3000,
            action,
          });
        }
      })
      .catch((error) => {
        // Error 😨
        if (error.response) {
          console.log('error.response.data', error.response.data);
          console.log('error.response.status', error.response.status);
          console.log('error.response.headers', error.response.headers);

          // alert(JSON.stringify(error.response.data))
          const action = (key) => (
            <React.Fragment>
              <Button
                onClick={() => {
                  this.props.closeSnackbar(key);
                }}
              >
                Dismiss
              </Button>
            </React.Fragment>
          );

          for (const [key, value] of Object.entries(error.response.data)) {
            console.log('key', key);
            this.props.enqueueSnackbar(key + ' - ' + value, {
              variant: 'error',
              autoHideDuration: 3000,
              action,
            });
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }
  FormSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('access');
    const expiryNumberconvertion = parseInt(this.state.expiry);
    const data = {
      name: this.state.product_name,
      channel: this.state.platform,
      expiry: expiryNumberconvertion,
    };

    console.log(data);
    if (this.state.expiry.length === 0) {
      this.setState({
        expiryError: true,
      });
    } else {
      axios('https://onesit.in-d.ai/api/applications/', {
        method: 'POST',
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          console.log(res);
          const serverData = res.data;
          this.setState({ serverData: serverData });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  onCreateAnApp = (event) => {
    // console.log(this.state.fname);
    event.preventDefault();
    const token = localStorage.getItem('access');
    if (
      this.state.applicationName.length === 0 &&
      this.state.platform.length === 0 &&
      this.state.service.length === 0
    ) {
      alert('Please select required fields');
    } else {
      const data = {
        name: this.state.applicationName,
        platform: this.state.platform,
        service: this.state.service,
      };

      console.log(data);
      axios(JSONData.invoice_api_url + '/applications/', {
        method: 'POST',
        data: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((res) => {
          console.log('service', res.data.key);
          console.log(res);

          if (res.status === 201) {
            this.setState({ key: res.data.key });
            const action = (key) => (
              <React.Fragment>
                <Button
                  onClick={() => {
                    this.props.closeSnackbar(key);
                  }}
                >
                  Dismiss
                </Button>
              </React.Fragment>
            );
            const message = 'Application created successfully';
            this.props.enqueueSnackbar(message, {
              variant: 'info',
              autoHideDuration: 3000,
              action,
            });

            // this.getAllApplications();

            setTimeout(() => {
              this.setState({ showModal: false, key: null });
            }, 2000);
          }
        })
        .catch((error) => {
          // Error 😨
          if (error.response) {
            // console.log("error.response.data", error.response.data);
            // console.log("error.response.status", error.response.status);
            // console.log("error.response.headers", error.response.headers);

            // alert(JSON.stringify(error.response.data))
            const action = (key) => (
              <React.Fragment>
                <Button
                  onClick={() => {
                    this.props.closeSnackbar(key);
                  }}
                >
                  Dismiss
                </Button>
              </React.Fragment>
            );

            for (const [key, value] of Object.entries(error.response.data)) {
              console.log('key', key);
              this.props.enqueueSnackbar(key + ' - ' + value, {
                variant: 'error',
                autoHideDuration: 3000,
                action,
              });
            }
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log('Error', error.message);
          }
          console.log(error.config);
        });
    }
  };
  handleDelete = (deletedata) => {
    const token = localStorage.getItem('access');
    console.log(deletedata);
    axios(`https://onesit.in-d.ai/api/applications/${deletedata}/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => this.getDatafromApi())
      .catch((err) => console.log(err));
    this.props.history.push('/Products');
    this.forceUpdate();
  };
  Gettingbuttonvalue = (e) => {
    this.setState({ product_name: e.target.value });
  };
  render() {
    const application = this.state.applicationData;
    console.log(application);
    const { expiryError } = this.state;

    const options = {
      filterType: 'select',
      responsive: 'scroll',
      search: true,
      print: true,
      viewColumns: false,
      download: true,
      rowHover: true,
      selectableRowsOnClick: false,
      selectableRows: false,
      onRowsSelect: this.onRowsSelect,
      rowsSelected: this.state.rowsSelected,

      textLabels: {
        body: {
          noMatch: 'Sorry, there is no matching data to display',
          // noMatch: this.state.loading === true ?
          // 'Sorry, there is no matching data to display':
          // <Loader msg={"Please wait, Loading Pool Data"} /> ,
          toolTip: 'Sort',
          columnHeaderTooltip: (column) => `Sort for ${column.label}`,
        },
        filter: {
          all: 'All',
          title: 'FILTERS',
          reset: 'RESET',
        },

        selectedRows: {
          text: 'row(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows',
        },
      },
    };

    const columnsWise = [
      // {
      //   name: "name",
      //   label: "name",
      //   options: {
      //     filter: false,
      //     sort: true,
      //   },
      // },
      {
        name: 'id',
        label: 'id',
        options: {
          filter: false,
          sort: true,
          display: false,
        },
      },

      {
        name: 'user',
        label: 'user id',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'channel',
        label: 'channel',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'key',
        label: 'key',
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: '',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const mainkey = tableMeta.rowData[3];
            return (
              <React.Fragment>
                <Button
                  id='copyBtn'
                  variant='outlined'
                  size='small'
                  color='primary'
                >
                  <CopyToClipboard
                    text={mainkey}
                    onCopy={() => this.copied(mainkey)}
                  >
                    <FileCopyIcon className='filecopy'></FileCopyIcon>
                  </CopyToClipboard>
                </Button>
                {/*
                <Button
                  id="copyBtn"
                  variant="outlined"
                  size="small"
                  color="primary"
                >
                  <EmailOutlinedIcon></EmailOutlinedIcon> 
                </Button>
                */}
              </React.Fragment>
            );
          },
        },
      },
      // {
      //   name: "expiry",
      //   label: "expiry",
      //   options: {
      //     filter: false,
      //     sort: true,
      //   },
      // },
      {
        name: 'revoke',
        label: 'revoke',
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            const mainID = tableMeta.rowData[1];
            return (
              <React.Fragment>
                <Button
                  id='deletebutton'
                  variant='outlined'
                  size='small'
                  color='primary'
                >
                  <DeleteSweepIcon
                    text={mainID}
                    onClick={() => this.handleDelete(mainID)}
                  ></DeleteSweepIcon>
                </Button>
              </React.Fragment>
            );
          },
        },
      },
    ];

    if (this.state.tokenRed) {
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    console.log(this.state.serviceList);
    return (
      <div className='main'>
        <SideMenu highlight='prod' />

        <div id='products' className='wrapper'>
          <div id='productsHead' className='row'>
            <h5>Products</h5>
          </div>

          <div id='productsMain' className='row'>
            <div id='productsLeft' className='col-12'>
              <div id='myProdList' className='grid-container'>
                {this.state.serviceList !== null
                  ? this.state.serviceList.map((item) => (
                    <div id='myProdEach' className='' key={item.id}>
                      <h6>{item.name}</h6>
                      <div className='row justify-content-center'>
                        <div id='blueCircle'>
                          {item.name === 'KYC' ? (
                            <img src={KYClogo} alt={item.name} />
                          ) : (
                            ''
                          )}
                          {item.name === 'Credit' ? (
                            <img src={Creditslogo} alt={item.name} />
                          ) : (
                            ''
                          )}
                          {item.name === 'Payables' ? (
                            <img src={Payableslogo} alt={item.name} />
                          ) : (
                            ''
                          )}
                          {item.name === 'Billspls' ? (
                            <img src={Receiptslogo} alt={item.name} />
                          ) : (
                            ''
                          )}
                          {item.name === 'Insurance' ? (
                            <img src={Insurancelogo} alt={item.name} />
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div className='row justify-content-center'>
                        <a
                          rel='noopener noreferrer'
                          target='_blank'
                          href={item.url}
                          id='bpcKeyButton'
                        >
                          Open Webapp
                        </a>

                        {/* <button id="bpcKeyButton" onClick={() => this.open(item.id)}>Crate An Application</button> */}
                      </div>
                      <div className='row justify-content-center'>
                        <button
                          type='button'
                          data-toggle='modal'
                          data-target='#exampleModal'
                          id='bpcKeyButton'
                          data-whatever='@getbootstrap'
                          className='generateapikey'
                          value={item.name}
                          onClick={this.Gettingbuttonvalue}
                        >
                          Generate API Key
                        </button>
                      </div>
                      <div>
                        <div
                          className='modal fade'
                          id='exampleModal'
                          tabIndex='-1'
                          role='dialog'
                          aria-labelledby='exampleModalLabel'
                          aria-hidden='true'
                        >
                          <div className='modal-dialog' role='document'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5
                                  className='modal-title'
                                  id='exampleModalLabel'
                                >
                                  Generate API key
                                </h5>
                                <button
                                  type='button'
                                  className='close'
                                  data-dismiss='modal'
                                  aria-label='Close'
                                >
                                  <span aria-hidden='true'>&times;</span>
                                </button>
                              </div>
                              <div className='modal-body'>
                                <Form onSubmit={this.FormSubmit}>
                                  <div className='form-group'>
                                    <label className='col-form-label'>
                                      Name:
                                    </label>
                                    <input
                                      type='text'
                                      className='form-control'
                                      name='name'
                                      value={this.state.product_name}
                                      disabled={true}
                                    />
                                  </div>
                                  <div
                                    className='col-lg-12 col-md-12 col-sm-12 col-xs-12'
                                    id='dropdown'
                                  >
                                    <label id='name'>Channel:</label>
                                    <select
                                      className='textInputforcreate'
                                      onChange={this.handlePlatformChange}
                                    >
                                      <option value=''>
                                        Select Platform
                                      </option>
                                      <option value='GEN'>
                                        Generic API integrations
                                      </option>
                                      <option value='PA'>
                                        Microsoft Power Automate
                                      </option>
                                      <option value='BP'>Blueprism</option>
                                      <option value='UIP'>UI Path</option> "
                                      <option value='WEB'>Web App</option>
                                      <option value='SDK'>Mobile SDKs</option>
                                    </select>
                                    {expiryError && (
                                      <h5 className='error'>
                                        Fields must be filled
                                      </h5>
                                    )}
                                  </div>
                                  <div
                                    className='form-group'
                                    id='expirycolumn'
                                  >
                                    <label className='col-form-label'>
                                      Expiry (in days):
                                    </label>
                                    <input
                                      type='number'
                                      name='expiry'
                                      className='form-control'
                                      value={this.state.expiry}
                                      onChange={this.handleChange}
                                      placeholder='Defaults to 1 hour if left blank'
                                    />
                                    {expiryError && (
                                      <h5 className='error'>
                                        Fields must be filled
                                      </h5>
                                    )}
                                  </div>
                                  <div className='modal-footer'>
                                    <button
                                      type='submit'
                                      className='saveButton'
                                    >
                                      Submit
                                    </button>
                                  </div>
                                </Form>
                              </div>
                              <Modal.Footer className='modal-footer'>
                                {this.state.serverData.key !== null ? (
                                  <React.Fragment>
                                    <input
                                      name='fname'
                                      value={this.state.serverData.key || ''}
                                      disabled={true}
                                      onChange={({ target: { value } }) =>
                                        this.setState({
                                          value,
                                          copied: false,
                                        })
                                      }
                                      className='textInputforcreate'
                                      type='text'
                                      placeholder=''
                                      size='50'
                                      required
                                    />
                                    <CopyToClipboard
                                      text={this.state.serverData.key}
                                      onCopy={() =>
                                        this.copied(this.state.serverData.key)
                                      }
                                    >
                                      <button className='saveButton'>
                                        Copy key
                                      </button>
                                    </CopyToClipboard>
                                  </React.Fragment>
                                ) : (
                                  // ) : (
                                  //     <React.Fragment>
                                  //       <input
                                  //         name="fname"
                                  //         disabled={true}
                                  //         className="textInputforcreate"
                                  //         type="text"
                                  //         placeholder=""
                                  //         size="50"
                                  //         required
                                  //       />
                                  //       <button
                                  //         disabled={true}
                                  //         className="saveButton"
                                  //       >
                                  //         Copy key
                                  //       </button>
                                  //     </React.Fragment>
                                  ''
                                )}
                              </Modal.Footer>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                  : ''}
              </div>
              <MUIDataTable
                title={'API keys'}
                data={application}
                columns={columnsWise}
                options={options}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSnackbar(Products);

import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import _store from './Store/store';
import './App.css';
import RootRouter from './RootRouter';
require('dotenv').config();
const { persistor, store } = _store();

function App() {
  console.log(process.env.REACT_APP_URL);
  return (
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <RootRouter />
      </Provider>
    </PersistGate>
  );
}

export default App;

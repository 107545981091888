import React, { useState,useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {ProfileUpdate} from '../Actions/userdetailsaction'
import {useDispatch} from 'react-redux'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
export default function FormDialog({open,handleClose,text,alert,profiledata,buttonname,buttonfunction}) {
  const dispatch = useDispatch()
  const [state,setState]=useState({first_Name:"",last_Name:"",company_size:"",dob:"",email:"",gender:"",language:"",middle_name:"",phone:"",gst:"",company:"",language:""})
  const RadioChange = (event) => {
    setState({...state,gst:event.target.value})
  };
  const [open1,setOpen]=useState(false)
  const SubmitHandler=(e)=> {
      e.preventDefault()
      dispatch(ProfileUpdate(state,(success)=> {
        if (success) {

        }
      }))
  }
  const handleChange = (key, text) => {
    setState({ ...state, [key]: text })
  }
  const handleClose1 = () => {
    setOpen(false)
  };

  const handleOpen = () => {
    setOpen(true)
  };
  useEffect(() => {
    const {first_Name="NA",last_Name="NA",company_size="NA",dob="NA",email="NA",gender="NA",language="NA",middle_name="NA",phone="NA",gst="NA",company="NA"}=profiledata&&profiledata?profiledata:{}
    setState({first_Name,last_Name,middle_name,email,phone,gst,gender,company_size,company,language,dob})
  }, [])
  const GenderChange=(event)=> {
    setState({...state,gender:event.target.value})
  }
  const languageChange = (event) => {
    console.log(event.target.value.length)
    const language=event.target.value
    if (language.length>0) {
      setState({...state,language:event.target.value})
    }else {
      setState({...state,language:event.target.value})
    }
  };
  console.log(state)
  const {first_Name,last_Name,middle_name,email,phone,gst,gender,company_size,company,language,dob}=state
  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{text?text:"Edit - Profile Information"}</DialogTitle>
        <form>
        <DialogContent>
          <div>
          {!text?
            <div>
            <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex flex-column">
                    <label>First Name</label>
                    <TextField onChange={e => handleChange("first_Name", e.target.value)} className="underline" type="text" value={first_Name} required />
                </div>
                <div className="d-flex flex-column">
                    <label>Middle Name</label>
                    <TextField type="text" className="underline" onChange={e => handleChange("middle_name", e.target.value)} value={middle_name} required/>
                </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex flex-column">
                    <label>Last Name</label>
                    <TextField onChange={e => handleChange("last_Name", e.target.value)} className="underline" name="lastname" type="text" value={last_Name} required />
                </div>
                <div className="d-flex flex-column">
                    <label>Email ID* </label>
                    <TextField type="email" className="underline" onChange={e => handleChange("email", e.target.value)} disabled value={email} required/>
                </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex flex-column">
                    <label>Mobile Number</label>
                    <TextField onChange={e => handleChange("phone", e.target.value)} className="underline" type="number" value={phone} required />
                </div>
                <div className="d-flex flex-column">
                    <label>Date of Birth</label>
                    <TextField type="date" className="underline" onChange={e => handleChange("dob", e.target.value)} className="datepicker" value={dob} required/>
                </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex flex-column">
                    <label>Gender</label>
                    <RadioGroup aria-label="gender" value={gender} onChange={GenderChange} className="d-flex flex-row">
                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                    </RadioGroup>
                </div>
                <div className="d-flex flex-column">
                    <label>Company Name</label>
                    <TextField type="text" className="underline" onChange={e => handleChange("company", e.target.value)} value={company} required/>
                </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex flex-column">
                    <label>GST enabled?</label>
                    <RadioGroup aria-label="GST" value={gst} onChange={RadioChange} className="d-flex flex-row">
                        <FormControlLabel value="True" control={<Radio />} label="True" />
                        <FormControlLabel value="False" control={<Radio />} label="False" />
                    </RadioGroup>
                </div>
                <div className="d-flex flex-column">
                    <label>Company Size</label>
                    <TextField onChange={e => handleChange("company_size", e.target.value)} className="underline" type="number" value={company_size} required />
                </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap">
            <div className="d-flex flex-column language">
              <label>Language</label>
              <FormControl variant="outlined" className="underline mb-3" id="standard-name">
                <Select
                
                  open={open1}
                  onClose={handleClose1}
                  onOpen={handleOpen}
                  value={language}
                  onChange={languageChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem  value={"English"}>English</MenuItem>
                  <MenuItem value={"Hindi"}>Hindi</MenuItem>
                </Select>
              </FormControl>
              </div>
            </div>
            </div>
            :<div className="deletealert">{alert}</div>}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {text?
            <Button onClick={buttonfunction} className="dialogbutton">
            {buttonname}
          </Button>
        :
          <Button type="submit" color="primary" onClick={SubmitHandler}>
            Save
          </Button>
          }
        </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

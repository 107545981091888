import React, { Component } from 'react';

import SideMenu from '../../components/side-menu/sidemenu';
import './billing.css';
import MUIDataTable from "mui-datatables";
import JSONData from '../../json/data.json';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { withSnackbar } from 'notistack';
import moment from 'moment';

import {
    Redirect
} from "react-router-dom";


class Billing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            showAddModal: false,
            showEditModal: false,
            showWalletModal: false,

            toReview_redirect: false,
            reviewed_redirect: false,
            dash_result: "",
            // data: null,
            cur_date: "",
            files: [],
            job_id: "",
            document_name: "",
            batchName: "",
            selected_id: "",
            selected_batch: "",
            val_redirect: false,
            dash_redirect: false,
            dig_flag: false,
            tokenRed: false,

            ahHighlight: "",
            uhHighlight: "",
            bhHighlight: "",
            selec: "uh",
            data: [],
            userdata: [],
            servicedata: [],
            channeldata: [],
            dummy:[],
            userTYpe: sessionStorage.getItem('userTYpe') || null
        }


        this.billingNavClick = this.billingNavClick.bind(this);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.openAddModal = this.openAddModal.bind(this);
        this.closeAddModal = this.closeAddModal.bind(this);

        this.openEditModal = this.openEditModal.bind(this);
        this.closeEditModal = this.closeEditModal.bind(this);

        this.openWalletModal = this.openWalletModal.bind(this);
        this.closeWalletModal = this.closeWalletModal.bind(this);

    }

    open() {
        this.setState({ showModal: true });
    }
    close() {
        this.setState({ showModal: false });
    }

    openAddModal() {
        this.setState({ showAddModal: true });
    }
    closeAddModal() {
        this.setState({ showAddModal: false });
    }

    openEditModal() {
        this.setState({ showEditModal: true });
    }
    closeEditModal() {
        this.setState({ showEditModal: false });
    }

    openWalletModal() {
        this.setState({ showWalletModal: true });
    }
    closeWalletModal() {
        this.setState({ showWalletModal: false });
    }


    // componentDidMount() {

    //     this.setState({
    //         ahHighlight: "selected",
    //         uhHighlight: "",
    //         bhHighlight: ""
    //     })
    // }

    billingNavClick(key) {

        if (key === "ah") {
            this.setState({
                ahHighlight: "selected",
                uhHighlight: "",
                bhHighlight: ""
            })
        }
        else if (key === "uh") {
            this.setState({
                uhHighlight: "selected",
                ahHighlight: "",
                bhHighlight: ""
            })
        }
        else {
            this.setState({
                bhHighlight: "selected",
                ahHighlight: "",
                uhHighlight: ""
            })
        }

        this.setState({ selec: key });
    }



    async componentDidMount() {
        const token = localStorage.getItem('access');

        if (token !== null) {
            if(this.state.userTYpe === 'admin'){
                this.getstats();
            }
           
            this.getGroupUser();
            this.getGroupService();
            this.getGroupChannel();

        } else {

            this.props.history.push("/")
        }


    }


    getGroupUser = () => {

        const token = localStorage.getItem('access');
        const data = {
            "token": token
        };
        // console.log(data);
        axios(JSONData.invoice_api_url + '/logs/group/?field=user', {
            method: 'GET',
            data: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(res => {
            // console.log("field=user", res.data);

            if (res.status === 200) {
                console.log("user data:",res.data)
                for (let i=0;i<res.data.result.Data.length;i++) {
                    var data={
                        name:res.data.result.Data[i].name.slice(0,15)+"...",
                        count:res.data.result.Data[i].count,
                        usage_cost:res.data.result.Data[i].usage_cost
                    }
                    this.state.dummy.push(data)
                }
                this.setState({ userdata: this.state.dummy })


                const action = key => (
                    <React.Fragment>
                        <Button onClick={() => { this.props.closeSnackbar(key) }}>
                            Dismiss
                        </Button>
                    </React.Fragment>
                );
                const message = "Data loaded"
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 3000,
                    action
                });

            }

        }).catch((error) => {
            if (error.response) {

                console.log("error.response.data", error.response.data);
                console.log("error.response.status", error.response.status);
                console.log("error.response.headers", error.response.headers);

                if (error.response.status === 404) {
                    const message = "Not Found, The requested resource was not found on this server."
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                }

                else if (error.response.status === 500) {
                    const message = "Server Error (500)"
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }

                else if (error.response.status === 401) {
                    if (error.response.data.code === 'token_not_valid') {

                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                                </Button>
                            </React.Fragment>
                        );

                        const message = error.response.data.detail
                        this.props.enqueueSnackbar(message, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });
                        this.props.history.push("/")

                    } else if (error.response.data.code === 'bad_authorization_header') {

                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                            </Button>
                            </React.Fragment>
                        );

                        const message = error.response.data.detail
                        this.props.enqueueSnackbar(message, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });

                        this.props.history.push("/")

                    } else {


                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                                </Button>
                            </React.Fragment>
                        );

                        for (const [key, value] of Object.entries(error.response.data)) {
                            // console.log("key", key)
                            this.props.enqueueSnackbar(key + " - " + value, {
                                variant: 'error',
                                autoHideDuration: 3000,
                                action
                            });
                        }
                    }
                }
                else {

                    // alert(JSON.stringify(error.response.data));
                    const action = key => (
                        <React.Fragment>
                            <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                Dismiss
                        </Button>
                        </React.Fragment>
                    );

                    for (const [key, value] of Object.entries(error.response.data)) {
                        // console.log("key", key)
                        this.props.enqueueSnackbar(key + " - " + value, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });
                    }



                }

            } else if (error.request) {

                console.log(error.request);

            } else {

                console.log('Error', error.message);
            }
            // console.log(error.config);
        });
    }

    getGroupService = () => {

        const token = localStorage.getItem('access');
        const data = {
            "token": token
        };
        // console.log(data);
        axios(JSONData.invoice_api_url + '/logs/group/?field=service', {
            method: 'GET',
            data: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(res => {
            // console.log("field=user", res);

            if (res.status === 200) {
                console.log("service data:",res.data)
                this.setState({ servicedata: res.data.result.Data })
                


                const action = key => (
                    <React.Fragment>
                        <Button onClick={() => { this.props.closeSnackbar(key) }}>
                            Dismiss
                        </Button>
                    </React.Fragment>
                );
                const message = "Data loaded"
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 3000,
                    action
                });

            }

        }).catch((error) => {
            if (error.response) {

                console.log("error.response.data", error.response.data);
                console.log("error.response.status", error.response.status);
                console.log("error.response.headers", error.response.headers);

                if (error.response.status === 404) {
                    const message = "Not Found, The requested resource was not found on this server."
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                }

                else if (error.response.status === 500) {
                    const message = "Server Error (500)"
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }

                else if (error.response.status === 401) {
                    if (error.response.data.code === 'token_not_valid') {

                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                                </Button>
                            </React.Fragment>
                        );

                        const message = error.response.data.detail
                        this.props.enqueueSnackbar(message, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });
                        this.props.history.push("/")

                    } else if (error.response.data.code === 'bad_authorization_header') {

                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                            </Button>
                            </React.Fragment>
                        );

                        const message = error.response.data.detail
                        this.props.enqueueSnackbar(message, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });

                        this.props.history.push("/")

                    } else {


                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                                </Button>
                            </React.Fragment>
                        );

                        for (const [key, value] of Object.entries(error.response.data)) {
                            // console.log("key", key)
                            this.props.enqueueSnackbar(key + " - " + value, {
                                variant: 'error',
                                autoHideDuration: 3000,
                                action
                            });
                        }
                    }
                }
                else {

                    // alert(JSON.stringify(error.response.data));
                    const action = key => (
                        <React.Fragment>
                            <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                Dismiss
                        </Button>
                        </React.Fragment>
                    );

                    for (const [key, value] of Object.entries(error.response.data)) {
                        // console.log("key", key)
                        this.props.enqueueSnackbar(key + " - " + value, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });
                    }



                }

            } else if (error.request) {

                console.log(error.request);

            } else {

                console.log('Error', error.message);
            }
            // console.log(error.config);
        });
    }


    getGroupChannel = () => {

        const token = localStorage.getItem('access');
        const data = {
            "token": token
        };
        // console.log(data);
        axios(JSONData.invoice_api_url + '/logs/group/?field=channel', {
            method: 'GET',
            data: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(res => {
            // console.log("field=user", res);

            if (res.status === 200) {
                console.log("Channnel Data :",res.data.result.Data)
                this.setState({ channeldata: res.data.result.Data })


                const action = key => (
                    <React.Fragment>
                        <Button onClick={() => { this.props.closeSnackbar(key) }}>
                            Dismiss
                        </Button>
                    </React.Fragment>
                );
                const message = "Data loaded"
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 3000,
                    action
                });

            }

        }).catch((error) => {
            if (error.response) {

                console.log("error.response.data", error.response.data);
                console.log("error.response.status", error.response.status);
                console.log("error.response.headers", error.response.headers);

                if (error.response.status === 404) {
                    const message = "Not Found, The requested resource was not found on this server."
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                }

                else if (error.response.status === 500) {
                    const message = "Server Error (500)"
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }

                else if (error.response.status === 401) {
                    if (error.response.data.code === 'token_not_valid') {

                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                                </Button>
                            </React.Fragment>
                        );

                        const message = error.response.data.detail
                        this.props.enqueueSnackbar(message, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });
                        this.props.history.push("/")

                    } else if (error.response.data.code === 'bad_authorization_header') {

                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                            </Button>
                            </React.Fragment>
                        );

                        const message = error.response.data.detail
                        this.props.enqueueSnackbar(message, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });

                        this.props.history.push("/")

                    } else {


                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                                </Button>
                            </React.Fragment>
                        );

                        for (const [key, value] of Object.entries(error.response.data)) {
                            // console.log("key", key)
                            this.props.enqueueSnackbar(key + " - " + value, {
                                variant: 'error',
                                autoHideDuration: 3000,
                                action
                            });
                        }
                    }
                }
                else {

                    // alert(JSON.stringify(error.response.data));
                    const action = key => (
                        <React.Fragment>
                            <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                Dismiss
                        </Button>
                        </React.Fragment>
                    );

                    for (const [key, value] of Object.entries(error.response.data)) {
                        // console.log("key", key)
                        this.props.enqueueSnackbar(key + " - " + value, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });
                    }



                }

            } else if (error.request) {

                console.log(error.request);

            } else {

                console.log('Error', error.message);
            }
            // console.log(error.config);
        });
    }

    getstats = () => {

        const token = localStorage.getItem('access');
        const data = {
            "token": token
        };
        // console.log(data);
        axios(JSONData.invoice_api_url + '/credits/', {
            method: 'GET',
            data: JSON.stringify(data),
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        }).then(res => {
            // console.log("applications List", res);

            if (res.status === 201) {

                this.setState({ data: res.data })


                const action = key => (
                    <React.Fragment>
                        <Button onClick={() => { this.props.closeSnackbar(key) }}>
                            Dismiss
                        </Button>
                    </React.Fragment>
                );
                const message = "Data loaded"
                this.props.enqueueSnackbar(message, {
                    variant: 'info',
                    autoHideDuration: 3000,
                    action
                });

            }

        }).catch((error) => {
            if (error.response) {

                console.log("error.response.data", error.response.data);
                console.log("error.response.status", error.response.status);
                console.log("error.response.headers", error.response.headers);

                if (error.response.status === 404) {
                    const message = "Not Found, The requested resource was not found on this server."
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });

                }

                else if (error.response.status === 500) {
                    const message = "Server Error (500)"
                    this.props.enqueueSnackbar(message, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }

                else if (error.response.status === 401) {
                    if (error.response.data.code === 'token_not_valid') {

                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                                </Button>
                            </React.Fragment>
                        );

                        const message = error.response.data.detail
                        this.props.enqueueSnackbar(message, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });
                        this.props.history.push("/")

                    } else if (error.response.data.code === 'bad_authorization_header') {

                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                            </Button>
                            </React.Fragment>
                        );

                        const message = error.response.data.detail
                        this.props.enqueueSnackbar(message, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });

                        this.props.history.push("/")

                    } else {


                        const action = key => (
                            <React.Fragment>
                                <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                    Dismiss
                                </Button>
                            </React.Fragment>
                        );

                        for (const [key, value] of Object.entries(error.response.data)) {
                            // console.log("key", key)
                            this.props.enqueueSnackbar(key + " - " + value, {
                                variant: 'error',
                                autoHideDuration: 3000,
                                action
                            });
                        }
                    }
                }
                else {

                    // alert(JSON.stringify(error.response.data));
                    const action = key => (
                        <React.Fragment>
                            <Button onClick={() => { this.props.closeSnackbar(key) }}>
                                Dismiss
                        </Button>
                        </React.Fragment>
                    );

                    for (const [key, value] of Object.entries(error.response.data)) {
                        // console.log("key", key)
                        this.props.enqueueSnackbar(key + " - " + value, {
                            variant: 'error',
                            autoHideDuration: 3000,
                            action
                        });
                    }



                }

            } else if (error.request) {

                console.log(error.request);

            } else {

                console.log('Error', error.message);
            }
            // console.log(error.config);
        });
    }

    render() {
        // console.log(this.state.userdata)
        // let display;


        if (this.state.tokenRed) {
            return <Redirect to={{
                pathname: "/",
            }} />
        }

        const options = {
            filterType: 'select',
            search: true,
            print: true,
            viewColumns: false,
            download: true,
            rowHover: true,
            selectableRowsOnClick: false,
            selectableRows: false,
            onRowsSelect: this.onRowsSelect,
            rowsSelected: this.state.rowsSelected,
            textLabels: {
                body: {
                    noMatch: 'Sorry, there is no matching data to display',
                    // noMatch: this.state.loading === true ?
                    // 'Sorry, there is no matching data to display':
                    // <Loader msg={"Please wait, Loading Pool Data"} /> ,
                    toolTip: 'Sort',
                    columnHeaderTooltip: (column) => `Sort for ${column.label}`,
                },
                filter: {
                    all: 'All',
                    title: 'FILTERS',
                    reset: 'RESET',
                },

                selectedRows: {
                    text: 'row(s) selected',
                    delete: 'Delete',
                    deleteAria: 'Delete Selected Rows',
                },
            },
        };
        const columns = [

            // {
            //     name: 'user',
            //     label: 'user',
            //     options: {
            //         filter: false,
            //         sort: true,
            //     },
            // },

            {
                name: 'balance',
                label: 'balance',
                options: {
                    filter: false,
                    sort: true,
                },
            },

            {
                name: "ctype",
                label: 'Credit type',
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {value === 'PRO' ? 'Promo Credit' : ''}
                                {value === 'TRL' ? 'Trial Credit' : ''}
                                {value === 'CSH' ? 'Cash Credit' : ''}
                            </React.Fragment>
                        );
                    },
                }
            },
            {
                name: "create_date",
                label: 'issue date',
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {moment(value).format("D/M/YYYY")}
                            </React.Fragment>
                        );
                    },
                }
            },
            {

                name: "expiry_date",
                label: 'expiry_date',
                options: {
                    filter: false,
                    sort: false,
                    empty: true,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <React.Fragment>
                                {value !== null ? moment(value).format("D/M/YYYY") : ''}

                            </React.Fragment>
                        );
                    },
                }
            },
        ];


        // [{"name": "kamesh1164@gmail.com", "count": 14, "usage_cost": 11.0}]
        const columnsWise = [

            {
                name: 'name',
                label: 'name',
                options: {
                    filter: false,
                    sort: true,
                },
            },

            {
                name: 'count',
                label: 'count',
                options: {
                    filter: false,
                    sort: true,
                },
            },
            {
                name: 'usage_cost',
                label: 'usage_cost',
                options: {
                    filter: false,
                    sort: true,
                },
            },
        ];

        // return null;

        return (
            <div className="main">

                <SideMenu highlight="bill" />


                <div id="toReview" className="wrapper">
                    <div id="invoicesHead" className="row">
                        <h5>Usage History</h5>
                    </div>

                    <div id="usagePage">
                        {/* <div className="container-fluid">
                            <div id="topCards" className="grid-container">
                                <div className="row">

                                    <div className="col-md-3 col-sm-12">
                                        <div id="topCardsEach">
                                            <h5>Total Usage</h5>
                                            <p>Current month total</p>
                                            <h3>1179</h3>
                                        </div>
                                    </div>
                                    <div id="" className="col-md-3 col-sm-12">
                                        <div id="topCardsEach">
                                            <h5>Todays Usage</h5>
                                            <p>Todays Usage</p>
                                            <h3>100</h3>
                                        </div>
                                    </div>
                                    <div id="" className="col-md-3 col-sm-12">
                                        <div id="topCardsEach">
                                            <h5>User</h5>
                                            <p>Total Users</p>
                                            <h3>5</h3>
                                        </div>
                                    </div>
                                    <div id="" className="col-md-3 col-sm-12">
                                        <div id="topCardsEach">
                                            <h5>Products</h5>
                                            <p>Total Products Subscribe</p>
                                            <h3>5</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> */}

{this.state.userTYpe === 'admin'?
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">

                                    <MUIDataTable
                                        title={'Credit Balance'}
                                        data={this.state.data}
                                        columns={columns}
                                        options={options}
                                    />

                                </div>
                            </div>
                        </div> : '' }

                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-md-4 tablemargin" style={{display: 'table', tableLayout:'fixed', width:'100%'}}>

                                    <MUIDataTable
                                        title={'Users'}
                                        data={this.state.userdata}
                                        columns={columnsWise}
                                        options={options}
                                    />
                                </div>
                                <div className="col-md-4 tablemargin">
                                    <MUIDataTable
                                        title={'Services'}
                                        data={this.state.servicedata}
                                        columns={columnsWise}
                                        options={options}
                                    />
                                </div>
                                <div className="col-md-4 tablemargin">
                                    <MUIDataTable
                                        title={'Channels'}
                                        data={this.state.channeldata}
                                        columns={columnsWise}
                                        options={options}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

export default (withSnackbar(Billing));
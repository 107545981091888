// import React, { Component } from "react";

// import "./register.css";

// import ReactPhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// import { Redirect,NavLink } from "react-router-dom";
// import JSONData from "../../json/data.json";
// import axios from "axios";
// import { withSnackbar } from "notistack";
// import Button from "@material-ui/core/Button";
// import password_hide from "../../images/password_hide.png";
// import password_show from "../../images/password_show.png";
// class Register extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       phone: "",
//       fname: "",
//       lname: "",
//       email: "",
//       Password: "",
//       company: "",
//       token: "",
//       formType: true,
//       backgroundColor: "",
//       passwordError:"",
//       passwordSuccess:"",
//       emailbutton:true,
//       emailError:"",
//       confirmbutton:"",
//       confirmError:"",
//       phonebutton:true,
//       phoneError:"",
//       fnamebutton:true,
//       fnameError:"",
//       lnamebutton:true,
//       lnameError:"",
//       passwordbutton:true,
//       confirmPassword:"",
//       hidden:true,
//       hidden1:true
//     };
//   }

//   handleFirstnameChange = (e) => {
//     const fname=e.target.value.replace(/\s+/g, '');
//     const regex = new RegExp("^[a-zA-Z]+$");
//     if (fname.length!==0){
//       if (regex.test(fname)) {
//         this.setState({...this.state,fnameError:"",fname:fname,fnamebutton:false})
//       }else {
//         this.setState({...this.state,fnameError:"Please enter only letters",fnamebutton:true})
//       }
//     }else {
//       this.setState({...this.state,fnameError:"Field Required",fnamebutton:true})
//     }
//   };
//   PasswordtoggleShow = () => {
//     this.setState({ hidden: !this.state.hidden });
//   };
//   confirmtoggleShow=()=> {
//     this.setState({ hidden1: !this.state.hidden1 });
//   }
//   handleLastnameChange = (e) => {
//     const lname=e.target.value.replace(/\s+/g, '');
//     const regex = new RegExp("^[a-zA-Z]+$");
//     if (lname.length!==0){
//       if (regex.test(lname)) {
//         this.setState({...this.state,lnameError:"",lname:lname,lnamebutton:false})
//       }else {
//         this.setState({...this.state,lnameError:"Please enter only letters",lnamebutton:true})
//       }
//     }else {
//       this.setState({...this.state,lnameError:"Field Required",lnamebutton:true})
//     }
//   };

//   handleEmailChange = (e) => {
//     const email=e.target.value
//     if (email.length!==0){
//         if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
//             this.setState({...this.state,emailError:"",email:email,emailbutton:false})
//         }else {
//             this.setState({...this.state,emailError:"Email is not valid",emailbutton:true})
//         }
//     }else {
//         this.setState({...this.state,emailError:"Field Required",emailbutton:true})
//     }
//   };

//   handleConfirmPasswordChange = (e) => {
//       const confirmPassword=e.target.value
//       if (this.state.Password===confirmPassword) {
//             this.setState({...this.state,confirmError:"",confirmbutton:false,confirmPassword:confirmPassword})
//       }else {
//           this.setState({...this.state,confirmbutton:true,confirmError:"Please Enter same Password as above"})
//       }
//   };

//   handlePasswordChange = (e) => {
//     const Password=e.target.value
//     const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
//     const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
//     if (Password.length>=6){
//     if (strongRegex.test(Password)) {
//         console.log("strong")
//       this.setState({ ...this.state,Password: Password,passwordSuccess:"#0F9D58",passwordError:"Strong Password",passwordbutton:false});
//     } else if (mediumRegex.test(Password)) {
//         console.log("medium")
//       this.setState({...this.state,Password: Password,passwordError:"please Use atleast one Special Character and one number",passwordSuccess:"",passwordbutton:true });
//     } else {
//       this.setState({ ...this.state,Password: Password,passwordError:"Please use atleast one Captial letter",passwordSuccess:"",passwordbutton:true });
//     }
//     }else {
//         this.setState({...this.state,Password: Password,passwordError:"Password should be minium 6 char",passwordSuccess:"",passwordbutton:true})
//     }
//   };

//   handleOnChange = (value) => {
//     const re = /^[0-9\b]+$/;
//     if (value.length!==0){
//       if (re.test(value)&&value.length===12) {
//          this.setState({...this.state,phone: value,phonebutton:false,phoneError:"",})
//       }
//       else {
//       this.setState({...this.state,phoneError:"Phone number should be in 10 digits and should contain only numbers",phonebutton:true})
//     }
//   }else {
//     this.setState({...this.state,phoneError:"Field Required",phonebutton:true})
//   }
//   };

//   onSignUp = (event) => {
//     event.preventDefault();
//     if (this.state.Password !== "" &&this.state.Password === this.state.confirmPassword) {
//       const data = {
//         first_name: this.state.fname,
//         last_name: this.state.lname,
//         password: this.state.Password,
//         email: this.state.email,
//         phone: this.state.phone,
//       };
//       console.log(data);
//       axios(JSONData.invoice_api_url + "/users/", {
//         method: "POST",
//         data: JSON.stringify(data),
//         headers: {
//           "Content-Type": "application/json",
//         },
//       })
//         .then((res) => {
//           if (res.status === 201) {
//             this.props.history.push("/Register/activate-acount");
//           }
//         })
//         .catch((error) => {
//           if (error.response) {
//             const action = (key) => (
//               <React.Fragment>
//                 <Button
//                   onClick={() => {
//                     this.props.closeSnackbar(key);
//                   }}
//                 >
//                   Dismiss
//                 </Button>
//               </React.Fragment>
//             );

//             for (const [key, value] of Object.entries(error.response.data)) {
//               console.log("key", key);
//               this.props.enqueueSnackbar(key + " - " + value, {
//                 variant: "error",
//                 autoHideDuration: 3000,
//                 action,
//               });
//             }
//           } else if (error.request) {
//             console.log(error.request);
//           } else {
//             console.log("Error", error.message);
//           }
//           console.log(error.config);
//         });
//     } else {
//       const action = (key) => (
//         <React.Fragment>
//           <Button
//             onClick={() => {
//               this.props.closeSnackbar(key);
//             }}
//           >
//             Dismiss
//           </Button>
//         </React.Fragment>
//       );

//       const msg = "Please enter the same password as above!";
//       this.props.enqueueSnackbar(msg, {
//         variant: "error",
//         autoHideDuration: 3000,
//         action,
//       });
//     }
//   };

//   componentDidMount() {
//     const query = new URLSearchParams(this.props.location.search);
//     const token = query.get("token");
//   }
//   render() {
//     return (
//       <div className="signUpMain">
//         <div id="signUpHead">
//           <div className="">
//             <div id="signUpHeadRow" className="row container">
//               <div className="row">
//                 <div className="mainLogo"></div>
//                 <h1>Authentication and Authorization</h1>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div id="signUpBody" className="container row justify-content-center">
//           <div className="row justify-content-center">
//             <div id="signUpForm">
//               <h1>One login for all IN-D products</h1>
//               <form>
//                 <div className="row">
//                   <div
//                     id="fn"
//                     className="col-lg-6 col-md-12 col-sm-12 col-xs-12"
//                   >
//                     <label id="name">First Name</label>
//                     <input
//                       name="fname"
//                       onChange={this.handleFirstnameChange}
//                       className="textInputforName"
//                       type="text"
//                       placeholder=""
//                       size="50"
//                       required
//                     />
//                   <div className="errormsg">{this.state.fnameError}</div>
//                   </div>

//                   <div
//                     id="space"
//                     className="col-lg-6 col-md-12 col-sm-12 col-xs-12"
//                   >
//                     <label id="name">Last Name</label>
//                     <input
//                       name="lname"
//                       onChange={this.handleLastnameChange}
//                       className="textInputforName"
//                       type="text"
//                       placeholder=""
//                       size="50"
//                       required
//                     />
//                   <div className="errormsg">{this.state.lnameError}</div>
//                   </div>
//                 </div>
//                 {this.state.formType === true ? (
//                   <React.Fragment>
//                     <label id="name">Email</label>
//                     <input
//                       name="email"
//                       onChange={this.handleEmailChange}
//                       className="textInput"
//                       type="text"
//                       placeholder=""
//                       size="50"
//                       required
//                     />
//                   <div className="errormsg">{this.state.emailError}</div>
//                   </React.Fragment>
//                 ) : (
//                   ""
//                 )}
//                 <div id="" className="">
//                   <label>Password</label>
//                 </div>
//                 <div>
//                   <input
//                     name="password"
//                     onChange={this.handlePasswordChange}
//                     className="textInput"
//                     type={this.state.hidden ? "password" : "text"}
//                     placeholder=""
//                     id="password-field"
//                     size="50"
//                     required
//                   />
//                   <div onClick={this.PasswordtoggleShow} className="hide_div">
//                   {this.state.hidden?
//                     <img src={password_hide} alt="hide" className="hide" />
//                     :
//                     <img src={password_show} alt="hide" className="hide" />
//                   }
//                   </div>
//                 </div>
//                 <div className={`${this.state.passwordSuccess ? "passwordSuccess" : "errormsg"}`}>{this.state.passwordError}</div>
//                 <div id="" className="">
//                   <label>Confirm Password</label>
//                 </div>
//                 <div>
//                   <input
//                     name="password"
//                     onChange={this.handleConfirmPasswordChange}
//                     className="textInput"
//                     type={this.state.hidden1 ? "password" : "text"}
//                     placeholder=""
//                     id="password-field"
//                     size="50"
//                     required
//                   />
//                   <div onClick={this.confirmtoggleShow} className="hide_div">
//                   {this.state.hidden1?
//                     <img src={password_hide} alt="hide" className="hide" />
//                     :
//                     <img src={password_show} alt="hide" className="hide" />
//                   }
//                   </div>
//                   <div className="errormsg">{this.state.confirmError}</div>
//                 </div>
//                 <div id="phoneNum">
//                   <label id="name">Phone Number</label>
//                   <ReactPhoneInput
//                     inputExtraProps={{
//                       name: "phone",
//                       required: true,
//                     }}
//                     country={"in"}
//                     value={this.state.phone}
//                     onChange={this.handleOnChange}
//                     style={{ width: "550" }}
//                     inputClass="phoneInp"
//                     dropdownClass="numDrop"
//                   />
//                   <div className="errormsg">{this.state.phoneError}</div>
//                 </div>
//                 <div id="privacyNote">
//                   <p>
//                     By clicking on SIGN UP FOR FREE you agree to our terms and
//                     you acknowledge having <br></br> read our{" "}
//                     <a href="/">privacy notice</a>
//                   </p>
//                 </div>

//                   <button
//                     onClick={this.onSignUp}
//                     type="submit"
//                     id="signUpButton"
//                     disabled={this.state.fnamebutton||this.state.lnamebutton||this.state.emailbutton||this.state.phonebutton||this.state.confirmbutton||this.state.passwordbutton}
//                   >
//                     Sign up for free
//                   </button>

//               </form>
//               <NavLink to="/" style={{ textDecoration: "none" }}>
//                 <button id="createButton">Login Here</button>
//               </NavLink>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default withSnackbar(Register);

import React, { useState, useContext, useRef, useEffect } from "react";
import "../login/login.css";
import { Button } from "@material-ui/core";
import password_hide from "../../images/eye-close.png";
import password_show from "../../images/eye-open.png";
import { Formik } from "formik";
import * as Yup from "yup";
import { Registration } from "../../Actions/userdetailsaction";
import { Link } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Spinner } from "react-bootstrap";
import IndLogo from "../../images/IND.png";

const RegistrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Required")
    .min(4, "Name must contain atleast 4 chars."),
  lastName: Yup.string()
    .required("Required")
    .min(4, "Name must contain atleast 4 chars."),
  email: Yup.string().email().required("Required"),
  phoneNumber: Yup.number()
    .required("Required")
    .min(10, "Phone number should contain atleast 10 number"),
  companyName: Yup.string().required("Required"),

  password: Yup.string()
    .required("Required")
    .min(6, "Password must contain atleast 6 chars"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});
function RegistrationPage(props) {
  const [passwordHidden, setPasswordHidden] = useState(true);
  const [confirmPasswordHidden, setConfirmPasswordHidden] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [score, setScore] = useState(0);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  let userAgentApplication;

  const togglePasswordHidden = () => {
    setPasswordHidden(!passwordHidden);
  };
  const toggleConfirmPasswordHidden = () => {
    setConfirmPasswordHidden(!confirmPasswordHidden);
  };

  const measurePasswordStrength = (password) => {
    let s = 0;
    let passwordStrength;
    let regexPositive = ["[A-Z]", "[a-z]", "[0-9]", "\\W"];
    regexPositive.forEach((regex, index) => {
      if (new RegExp(regex).test(password)) {
        s += 1;
      }
    });
    setScore(s);
    switch (s) {
      case 0:
        passwordStrength = "too weak";
      case 1:
        passwordStrength = "weak";
        break;
      case 2:
      case 3:
        passwordStrength = "good";
        break;
      case 4:
      case 5:
        passwordStrength = "strong";
        break;
    }
    setPasswordStrength(passwordStrength);
  };
  const TermsOnchange = (e) => {
    console.log(e.target);
    setCheckBox(!checkBox);
  };
  const validate = (e) => {
    let password = e.target.value;
    let errorMessage;
    if (password.length <= 0) {
      setScore(0);
    }
    let capsCount, smallCount, numberCount, symbolCount;
    if (password.length < 8) {
      setErrorMessage("password must be min 8 char");
    } else {
      capsCount = (password.match(/[A-Z]/g) || []).length;
      smallCount = (password.match(/[a-z]/g) || []).length;
      numberCount = (password.match(/[0-9]/g) || []).length;
      symbolCount = (password.match(/\W/g) || []).length;
      if (capsCount < 1) {
        errorMessage = "Must contain Caps!..";
      } else if (smallCount < 1) {
        errorMessage = "Must contain small letters!..";
      } else if (numberCount < 1) {
        errorMessage = "Must contain Number!..";
      } else if (symbolCount < 1) {
        errorMessage = "Must contain Symbol!..";
      }
      setErrorMessage(errorMessage);
      measurePasswordStrength(password);
    }
  };

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        phoneNumber: "",
        companyName: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        setLoader(true);
        Registration(values, (success) => {
          console.log(success, "success");
          if (success) {
            // setIsLoggedIn(localStorage.getItem("token"));
            setLoader(false);
          } else {
            setLoader(false);
          }
        });
        setSubmitting(false);
      }}
      validationSchema={RegistrationSchema}
    >
      {(props) => {
        let {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        console.log(values, touched, errors);
        return (
          <React.Fragment>
            <div className="Login_container Signup_container">
              <div className="Login_form">
                <div className="Login_header">
                  <img src={IndLogo} />
                  <div
                    style={{
                      color: "#3E3E3E",
                      fontFamily: "ProximaNovaR",
                      fontSize: "50px",
                      marginTop: "0.2rem",
                    }}
                  ></div>
                </div>
                <div>
                  <form onSubmit={handleSubmit}>
                    {/* Tow column grid */}
                    <div className="input-row">
                      <div className="input-with-error">
                        <input
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="First name"
                          size="50"
                          type="text"
                          required
                          className={
                            errors.firstName && touched.firstName
                              ? "error textInput"
                              : "validate textInput"
                          }
                          value={values.firstName}
                        />
                        {errors.firstName && touched.firstName && (
                          <div className="input-feedback">
                            {errors.firstName}
                          </div>
                        )}
                      </div>
                      <div className="input-with-error">
                        <input
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Last name"
                          size="50"
                          type="text"
                          required
                          className={
                            errors.lastName && touched.lastName
                              ? "error textInput"
                              : "validate textInput"
                          }
                          value={values.lastName}
                        />
                        {errors.lastName && touched.lastName && (
                          <div className="input-feedback">
                            {errors.lastName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="input-with-error">
                      <input
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Email address"
                        size="50"
                        type="email"
                        required
                        className={
                          errors.email && touched.email
                            ? "error textInput"
                            : "validate textInput"
                        }
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <div className="input-feedback">{errors.email}</div>
                      )}
                    </div>
                    <div className="input-with-error">
                      <input
                        // style={{
                        //   marginBottom: '1rem',
                        // }}
                        name="password"
                        onChange={(e) => {
                          handleChange(e);
                          validate(e);
                        }}
                        onBlur={handleBlur}
                        placeholder="Set Password"
                        size="50"
                        type={passwordHidden ? "password" : "text"}
                        required
                        className={
                          errors.password && touched.password
                            ? "error textInput"
                            : "validate textInput"
                        }
                        value={values.password}
                      />
                      <div onClick={togglePasswordHidden} className="hide_div">
                        {passwordHidden ? (
                          <img
                            src={password_hide}
                            alt="hide"
                            className="hide"
                          />
                        ) : (
                          <img
                            src={password_show}
                            alt="hide"
                            className="hide"
                          />
                        )}
                      </div>
                      {/* Password strength */}
                      <div className="password-strength">
                        <div className="password-strength-text">
                          Password Strength
                        </div>
                        {/* <progress max='100' value='30' /> */}
                        <LinearProgress
                          max={100}
                          value={
                            score <= 0
                              ? 0
                              : score <= 2
                              ? 30
                              : score < 4 && score > 2
                              ? 60
                              : 100
                          }
                          variant="determinate"
                          className="linear-progress"
                        />
                      </div>
                      {errors.password && touched.password && (
                        <div className="input-feedback">{errors.password}</div>
                      )}
                    </div>
                    <div className="input-with-error">
                      <input
                        name="confirmPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Confirm Password"
                        size="50"
                        type={confirmPasswordHidden ? "password" : "text"}
                        required
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? "error textInput"
                            : "validate textInput"
                        }
                        value={values.confirmPassword}
                      />
                      <div
                        onClick={toggleConfirmPasswordHidden}
                        className="hide_div"
                      >
                        {confirmPasswordHidden ? (
                          <img
                            src={password_hide}
                            alt="hide"
                            className="hide"
                          />
                        ) : (
                          <img
                            src={password_show}
                            alt="hide"
                            className="hide"
                          />
                        )}
                      </div>
                      {errors.confirmPassword && touched.confirmPassword && (
                        <div className="input-feedback">
                          {errors.confirmPassword}
                        </div>
                      )}
                    </div>

                    {/* Two column grid */}
                    <div className="input-row">
                      <div className="input-with-error">
                        <input
                          name="phoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Phone no"
                          size="50"
                          type="numeric"
                          required
                          className={
                            errors.phoneNumber && touched.phoneNumber
                              ? "error textInput"
                              : "validate textInput"
                          }
                          value={values.phoneNumber}
                        />
                        {errors.phoneNumber && touched.phoneNumber && (
                          <div className="input-feedback">
                            {errors.phoneNumber}
                          </div>
                        )}
                      </div>
                      <div className="input-with-error">
                        <input
                          name="companyName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Company name"
                          size="50"
                          type="text"
                          required
                          className={
                            errors.companyName && touched.companyName
                              ? "error textInput"
                              : "validate textInput"
                          }
                          value={values.companyName}
                        />
                        {errors.companyName && touched.companyName && (
                          <div className="input-feedback">
                            {errors.companyName}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="agree-terms">
                      <input type="checkbox" onChange={TermsOnchange} />I agree
                      to the
                      <a
                        style={{
                          fontSize: "11px",
                          marginTop: "13px",
                          marginLeft: "4px",
                          marginRight: "4px",
                        }}
                        href="https://in-d.ai/terms-of-use/"
                      >
                        Terms of Use
                      </a>
                      and
                      <a
                        style={{
                          fontSize: "11px",
                          marginTop: "13px",
                          marginLeft: "4px",
                          marginRight: "4px",
                        }}
                        href="https://in-d.ai/privacy-policy/"
                      >
                        Privacy Policy
                      </a>
                    </div>
                    <Button
                      disabled={loader || checkBox == false ? true : false}
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="signinbutton"
                    >
                      {loader ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        ""
                      )}
                      {loader ? <div>&nbsp;Signing In...</div> : "Sign up"}
                    </Button>
                  </form>
                </div>
              </div>
              <div className="footer">
                <a href="https://in-d.ai/" target="_blank">
                  Company Website
                </a>
                <a href="https://in-d.ai/contact/" target="_blank">
                  Contact us
                </a>
                <a href="https://in-d.ai/privacy-policy/" target="_blank">
                  Privacy Policy
                </a>
              </div>
              <div className="register">
                <p
                  style={{
                    position: "relative",
                    bottom: 0,
                  }}
                >
                  Already have an account?
                  <Link to="/">Login</Link>
                </p>
              </div>
            </div>
          </React.Fragment>
        );
      }}
    </Formik>
  );
}

export default RegistrationPage;

const InputWithError = ({
  handleBlur,
  handleChange,
  value,
  error,
  touched,
  placeholder,
}) => {
  <input
    name={value}
    placeholder={placeholder}
    onBlur={handleBlur}
    size="50"
    required
    onChange={handleChange}
    value={value}
    className={error && touched ? "error textInput" : "validate textInput"}
  />;
  {
    error && touched && <div className="input-feedback">{error}</div>;
  }
};

// / <InputWithError
// handleBlur={handleBlur}
// handleChange={handleChange}
// error={errors.email}
// value={values.email}
// placeholder="Email address"
// touched={touched.email}
// />

export default {
  GET_PROFILE_DATA_SUCCESS: "GET_PROFILE_DATA_SUCCESS",
  GET_PROFILE_DATA_FAIL: "GET_PROFILE_DATA_FAIL",
  GET_PROFILE_DATA_REQUEST: "GET_PROFILE_DATA_REQUEST",
  USER_REQUEST: "USER_REQUEST",
  USER_SUCCESS: "USER_SUCCESS",
  USER_FAILED: "USER_FAILED",
  PASSWORD_AGING_SUCCESS: "PASSWORD_AGING_SUCCESS",
  PASSWORD_AGING_FAILED: "PASSWORD_AGING_FAILED",
  LOGOUT: "LOGOUT",
  FORGET_EMAIL: "FORGET_EMAIL",
  USER_PASSWORD: "USER_PASSWORD",
};

import React, { Component } from 'react';
import './register.css';
import 'react-phone-input-2/lib/style.css';
import {  NavLink } from "react-router-dom";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <React.Fragment>
                <div className="main">
                    <div className="registerMsg">
                        <div className="mainLogo2"></div>
                        <h1>Authentication and Authorization</h1>
                        <div class="alert alert-primary" role="alert">
                     <div className="container text-center">
<p>We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.</p>
<p>If you do not receive a confirmation email, please check your spam folder. Also, please verify that you entered a valid email address in our sign-up form.</p>

                     </div>
                 </div>
                    <NavLink to="/" style={{ textDecoration: 'none' }}><button id="createButton">Sign In</button></NavLink>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Register;

// import React, { Component } from 'react';

// // import './login.css';

// import axios from 'axios';

// import {
//     Redirect,
//     NavLink,
// } from "react-router-dom";
// import JSONData from '../../json/data.json';
// // import JSONData from '../../json/data.json';

// class Login extends Component {

//     constructor(props) {
//         super(props);
//         this.state = {
//             access: "",
//             firstname: "",
//             redirect: false,
//             payredirect: false,
//             logredirect: false,
//             tokenRed: false,
//         }

//         this.routeChange = this.routeChange.bind(this);

//         this.handleUsernameChange = this.handleUsernameChange.bind(this);
//         this.handlePasswordChange = this.handlePasswordChange.bind(this);

//         this.onSignIn = this.onSignIn.bind(this);

//     }


//     handleUsernameChange = (e) => {
//         this.setState({ uname: e.target.value });
//     }

//     handlePasswordChange = (e) => {
//         this.setState({ password: e.target.value });
//     }


//     routeChange() {
//         let x = document.getElementById('eye-icon');
//         x.classList.toggle("fa-eye-slash");

//         if (document.getElementById('password-field').type === 'password') {
//             document.getElementById('password-field').type = 'text';
//         }
//         else {
//             document.getElementById('password-field').type = 'password';
//         }

//     }

//     onSignIn = event => {
//         // console.log(this.state.fname);
//         event.preventDefault();

//         const data = {
//             "email": this.state.uname,
//             "password": this.state.password,
//         };
//         // console.log(data);
//         axios(JSONData.invoice_api_url + '/token/', {
//             method: 'POST',
//             data: JSON.stringify(data),
//             headers: {
//                 // 'Authorization': `bearer ${token}`,
//                 'Content-Type': 'application/json'
//             },
//             crossdomain: true
//         }).then(res => {
//             // console.log(res.data);



//             if (res.status === 200) {
//                 this.setState({
//                     access: res.data.access,

//                 })

//                 localStorage.setItem('access', res.data.access);



//                 this.setState({ redirect: true });

//             }

//         }).catch(error => {
//             if (error.response === undefined) {
//                 alert(error)
//             }
//             else {
//                 if (error.response.data.message === "Token is invalid!" || error.response.data.message === "Not Authorized!") {
//                     alert(error.response.data.message);
//                     this.setState({ tokenRed: true });
//                 }
//                 else {
//                     alert(error.response.data.message)
//                 }

//             }
//         })



//     }



//     render() {
//         if (this.state.redirect) {
//             return <Redirect to={{
//                 pathname: "/Products",
//             }} />
//         }

//         if (this.state.tokenRed) {
//             return <Redirect to={{
//                 pathname: "/",
//             }} />
//         }

//         return (
//             <div className="loginMain">
//                 <div id="loginHead">
//                     <div className="container">
//                         <div className="row">
//                             <div className="mainLogo"></div>
//                             <h1>One</h1>
//                         </div>

//                     </div>
//                 </div>

//                 <div class="alert alert-primary" role="alert">
//                     <div className="container text-center">
//                     You have successfully registered. click on Sign in to access your account.
//                     </div>
//                 </div>

//                 <div id="loginBody" className="container row justify-content-center">

//                     <div className="row justify-content-center">
//                         <div id="loginForm">

//                             <h5 >Login to your account to access IN-D</h5>

//                             <form onSubmit={this.onSignIn}>
//                                 <label id="name">User Name</label>
//                                 <input name="uname" onChange={this.handleUsernameChange} className="textInput" type="text" placeholder="" size="50" />

//                                 <div id="pass" className="row">
//                                     <label>Password</label>
//                                     {/* <a href="/">Forgot Password?</a> */}
//                                 </div>
//                                 <div>
//                                     <input name="password" onChange={this.handlePasswordChange} className="textInput" type="password" placeholder="" id="password-field" size="50" />
//                                     <span id="eye-icon" toggle="#password-field" className="fa fa-lg fa-fw fa-eye field-icon toggle-password" onClick={this.routeChange}></span>
//                                 </div>



//                                 <button type="submit" id="signInButton">Sign In</button>

//                                 {/* <div id="checkBox">
//                                     <input className="checkInput" type="checkbox" /> Keep me signed in
//                                 </div> */}
//                             </form>


//                             <div id="newFull" className="row"> <hr /><div id="new">New to IN-D ?</div><hr /> </div>

//                             <NavLink to="/Register" style={{ textDecoration: 'none' }}><button id="createButton">Create your IN-D Account</button></NavLink>


//                         </div>
//                     </div>

//                 </div>

//             </div>
//         );
//     }
// }

// export default Login;
import React, { useState, useContext, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import "./SignupWithMicrosoft.css";
import IndLogo from "../../images/indlogo.png";
import { Link } from "react-router-dom";

const SignupWithMicrosoft = () => {
  // const { userdetails } = useSelector(({ userdetails }) => userdetails);
  // console.log(userdetails);
  const { forgetemail } = useSelector(({ userdetails }) => userdetails);
  console.log(forgetemail);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);

  return (
    <div className="SignupWithMicrosoft_container">
      <div className="header-text">
        Thanks for choosing <div className="main-text">IN-D</div>{" "}
      </div>
      <div className="thankyoutext">
        <div>
          Mail sent to <span>{forgetemail ? forgetemail : ""}</span> with the
          magic link.
        </div>
        <p>You can close this window now.</p>
      </div>
      <div className="logo">
        <div>
          <img src={IndLogo} height={50} style={{ objectFit: "contain" }} />
        </div>
      </div>
      {/* Footer */}
      <div className="SignupWithMicrosoft_footer">
        <a
          style={{
            zIndex: 1,
          }}
          href="https://in-d.ai/"
          target="_blank"
        >
          Company Website
        </a>
        <a
          style={{
            zIndex: 1,
          }}
          href="https://in-d.ai/contact/"
          target="_blank"
        >
          Contact us
        </a>
        <a
          style={{
            zIndex: 1,
          }}
          href="https://in-d.ai/privacy-policy/"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default SignupWithMicrosoft;

import React, { useState,useEffect } from "react";
import editPencil from "../../images/pen1.svg";
import ProfileModal from '../ProfileModal'
import './ProfileInformation.css'
export const SaveButton = ({edit,setEdit,handleClose,profiledata}) => {
  if (edit) {
      return <ProfileModal open={edit} handleClose={handleClose} profiledata={profiledata}/>
  }
  return (
    <div>
        <div className="sm-pen-round center" onClick={() => setEdit(true)}>
          <img src={editPencil} className="edit-pen" alt="edit pen" />
        </div>
    </div>
  );
};
const ProfileInformation = ({profiledata}) => {
  const [edit, setEdit] = useState(false);
  const handleClose = () => {
    setEdit(false);
  };
  useEffect(() => {
   
  }, [])
  const {First_Name="NA",last_Name="NA",company_size="NA",dob="NA",email="NA",gender="NA",language="NA",middle_name="NA",phone="NA",gst="NA",company="NA"}=profiledata==null?"":profiledata
  console.log(dob)
  return (
      <React.Fragment>
    <div>
    <div className="d-flex">
      <div className="detail-right-card center col-md-11">
        <div className="aadhar-details-info">
                <div className="d-flex flex-column">
                    <span className="details-label">First Name</span>
                    <input type="text"  value={First_Name}  className="aadhar-input name-input" disabled required/>
                </div>
                <div className="d-flex flex-column">
                    <span className="details-label">Middle Name</span>
                    <input type="text"  value={middle_name} className="aadhar-input name-input" disabled required/>
                </div>
                <div className="d-flex flex-column">
                    <span className="details-label">Last Name</span>
                    <input type="text"  value={last_Name} className="aadhar-input name-input" disabled required/>
                </div>
            </div>
        </div>
      <SaveButton edit={edit} setEdit={setEdit} handleClose={handleClose} profiledata={profiledata}/>
    </div>
    <div className="detail-right-card center col-md-11">
        <div className="aadhar-details-info">
                <div className="d-flex flex-column">
                    <span className="details-label">Email ID*</span>
                    <input type="text"  value={email} className="aadhar-input name-input" disabled required/>
                </div>
                <div className="d-flex flex-column">
                    <span className="details-label">Mobile Number*</span>
                    <input type="text"  value={phone} className="aadhar-input name-input" disabled required/>
                </div>
                <div className="d-flex flex-column">
                    <span className="details-label">Date of Birth</span>
                    <input type="text"  value={dob} className="aadhar-input name-input" disabled required/>
                </div>
            </div>
    </div>
    <div className="detail-right-card center col-md-11">
        <div className="aadhar-details-info">
                <div className="d-flex flex-column">
                    <span className="details-label">Gender</span>
                    <input type="text"  value={gender} className="aadhar-input name-input" disabled required/>
                </div>
                <div className="d-flex flex-column">
                    <span className="details-label">Company Size</span>
                    <input type="text"  value={company_size} className="aadhar-input name-input" disabled required/>
                </div>
                <div className="d-flex flex-column">
                    <span className="details-label">GST enabled?</span>
                    <input type="text"  value={gst} className="aadhar-input name-input" disabled required/>
                </div>
            </div>
    </div>
    <div className="detail-right-card center col-md-11">
        <div className="aadhar-details-info">
                <div className="d-flex flex-column">
                    <span className="details-label">Company</span>
                    <input type="text"  value={company} className="aadhar-input name-input" disabled required/>
                </div>
                <div className="d-flex flex-column">
                    <span className="details-label">Language</span>
                    <input type="text"  value={language} className="aadhar-input name-input" disabled required/>
                </div>
            </div>
    </div>
    </div>
    </React.Fragment>
  );
};

export default ProfileInformation;

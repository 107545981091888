// import React, { Component } from "react";
// import "./login.css";
// import axios from "axios";
// import { NavLink } from "react-router-dom";
// import { withSnackbar } from "notistack";
// import Button from "@material-ui/core/Button";
// import jwt from "jsonwebtoken"; // import dependency
// import password_hide from "../../images/password_hide.png";
// import password_show from "../../images/password_show.png";
// import Back from "../../images/back.svg";
// import { IND_ONE, PATH } from "../../Constants";
// import {
//   PasswordExpiry,
//   ForgetPassword,
// } from "../../Actions/userdetailsaction";
// import { connect } from "react-redux";
// import ProfileModal from "../../components/ProfileModal";
// let count = 0;
// class Login extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       access: "",
//       firstname: "",
//       email: "",
//       emailbutton: true,
//       emailError: "",
//       password: "",
//       passwordbutton: true,
//       passwordError: "",
//       hidden: true,
//       count: 0,
//       time: "",
//       forgetswift: true,
//       open: false,
//       modal: false,
//     };
//   }
//   handleUsernameChange = (e) => {
//     const email = e.target.value;
//     if (email.length !== 0) {
//       if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
//         this.setState({
//           ...this.state,
//           emailError: "",
//           email: email,
//           emailbutton: false,
//         });
//       } else {
//         this.setState({
//           ...this.state,
//           emailError: "Email is not valid",
//           emailbutton: true,
//         });
//       }
//     } else {
//       this.setState({
//         ...this.state,
//         emailError: "Field Required",
//         emailbutton: true,
//       });
//     }
//   };

//   handlePasswordChange = (e) => {
//     const pass = e.target.value;
//     if (pass.length > 7) {
//       this.setState({
//         ...this.state,
//         passwordbutton: false,
//         passwordError: "",
//         password: pass,
//       });
//     } else if (pass.length < 7) {
//       this.setState({
//         ...this.state,
//         passwordbutton: true,
//         passwordError: "Your password is not secure. Reset password",
//         password: pass,
//       });
//     } else {
//       this.setState({
//         ...this.state,
//         passwordbutton: true,
//         passwordError: "Please Enter your Password",
//         password: pass,
//       });
//     }
//   };
//   toggleShow = () => {
//     this.setState({ hidden: !this.state.hidden });
//   };

//   ForgetChange = () => {
//     this.setState({ forgetswift: false });
//   };
//   GettingCount = () => {
//     const data1 = {
//       email: this.state.email,
//     };
//     try {
//       axios(`${IND_ONE}${PATH.wrong_pass_return}`, {
//         method: "POST",
//         data: data1,
//       }).then((res) => {
//         const time = Math.floor(res.data.difference);
//         this.setState({ count: res.data.count, time: time });
//         if (res.data.count === 0) {
//           this.UpdateCount(res.data.count);
//           this.setState({ passwordError: `Please enter the correct Password` });
//         } else if (res.data.count <= 3) {
//           this.UpdateCount(res.data.count);
//           const attempts_left = 4 - res.data.count;
//           this.setState({
//             passwordError: `Please enter the correct Password.Attempts Left:${attempts_left}`,
//           });
//         } else if (res.data.count >= 4) {
//           this.setState({ passwordError: "" });
//           if (time > 60) {
//             console.log("Greater than 60 min");
//             this.UpdateCount(0);
//           } else {
//             const message =
//               "You have attempted wrong password for 3 times so Please try after 1 hour";
//             this.props.enqueueSnackbar(message, {
//               variant: "error",
//               autoHideDuration: 3000,
//             });
//           }
//         }
//       });
//     } catch (error) {
//       if (error && error.response) {
//         alert(error.response.data.message);
//       }
//     }
//   };
//   UpdateCount = (count) => {
//     const inccount = count + 1;
//     const data1 = {
//       email: this.state.email,
//       upd_count: inccount,
//     };
//     try {
//       axios(`${IND_ONE}${PATH.wrong_pass_update}`, {
//         method: "POST",
//         data: data1,
//       }).then((res) => {
//         console.log(res);
//       });
//     } catch (error) {
//       if (error && error.response) {
//         alert(error.response.data.message);
//       }
//     }
//   };
//   onSignIn = (event) => {
//     event.preventDefault();
//     const data = {
//       email: this.state.email,
//       password: this.state.password,
//     };
//     axios(`${IND_ONE}` + "/users/token/", {
//       method: "POST",
//       data: JSON.stringify(data),
//       headers: {
//         "Content-Type": "application/json",
//       },
//       crossdomain: true,
//     })
//       .then((res) => {
//         if (res.status === 200) {
//           this.props.PasswordExpiry(
//             res.data.access,
//             this.props.history,
//             (success) => {
//               if (success) {
//                 this.setState({ modal: true, open: true });
//               }
//             }
//           );
//           this.setState({
//             access: res.data.access,
//           });
//           localStorage.setItem("access", res.data.access);
//           var decode2 = jwt.decode(res.data.access);
//           sessionStorage.setItem("user_email", decode2.user_email);

//           if (decode2.is_admin === true) {
//             sessionStorage.setItem("userTYpe", "admin");
//           } else {
//             sessionStorage.setItem("userTYpe", "user");
//           }
//         }
//       })
//       .catch((error) => {
//         if (error.response) {
//           if (error.response.data.detail === "Password is incorrect") {
//             count += 1;
//             this.GettingCount();
//           } else {
//             const action = (key) => (
//               <React.Fragment>
//                 <Button
//                   onClick={() => {
//                     this.props.closeSnackbar(key);
//                   }}
//                 >
//                   Dismiss
//                 </Button>
//               </React.Fragment>
//             );

//             for (const [key, value] of Object.entries(error.response.data)) {
//               this.props.enqueueSnackbar(key + " - " + value, {
//                 variant: "error",
//                 autoHideDuration: 3000,
//                 action,
//               });
//             }
//           }
//         }
//       });
//   };
//   BackToLogin = () => {
//     this.setState({ forgetswift: true });
//   };
//   handleClose = () => {
//     this.setState({ open: false });
//   };
//   ButtonFunctionality = () => {
//     this.props.history.push("/PasswordReset");
//   };
//   ForgetPassword = (e) => {
//     e.preventDefault();
//     // this.props.PasswordExpiry(ForgetPassword(this.state.email));
//     this.props.ForgetPassword(this.state.email, (success) => {
//       if (success) {
//         this.props.history.push("/thankyou");
//       }
//     });
//   };
//   data = () => {
//     if (this.state.modal) {
//       return (
//         <ProfileModal
//           open={this.state.open}
//           handleClose={this.handleClose}
//           text={"Password Expired"}
//           alert={
//             "Your password is expired as you haven't updated it for the past 90 days. Please reset the password to continue."
//           }
//           buttonname={"Reset Password"}
//           buttonfunction={this.ButtonFunctionality}
//         />
//       );
//     }
//   };
//   render() {
//     return (
//       <div className="loginMain">
//         {this.data()}
//         <div>
//           <div className="loginHead">
//             <div className="mainLogo"></div>
//             <div className="logintext">Authentication and Authorization</div>
//           </div>
//         </div>
//         <div id="loginBody" className="justify-content-center">
//           {this.state.forgetswift ? (
//             <div className="row justify-content-center">
//               <div id="loginForm">
//                 <div>
//                   <h5>Sign in to IN-D</h5>
//                 </div>
//                 <form onSubmit={this.onSignIn}>
//                   <label id="name">Email Address</label>
//                   <input
//                     name="uname"
//                     onChange={this.handleUsernameChange}
//                     className="textInput"
//                     type="text"
//                     placeholder="you@example.com"
//                     size="50"
//                   />
//                   <div className="errormsg">{this.state.emailError}</div>
//                   <div id="pass" className="row">
//                     <label>Password</label>
//                   </div>
//                   <div>
//                     <input
//                       name="password"
//                       onChange={this.handlePasswordChange}
//                       className="textInput"
//                       type={this.state.hidden ? "password" : "text"}
//                       placeholder="Password"
//                       id="password-field"
//                       size="50"
//                     />
//                     <a className="routing" onClick={this.ForgetChange}>
//                       Forgot Password?
//                     </a>
//                     <div onClick={this.toggleShow} className="hide_div">
//                       {this.state.hidden ? (
//                         <img src={password_hide} alt="hide" className="hide" />
//                       ) : (
//                         <img src={password_show} alt="hide" className="hide" />
//                       )}
//                     </div>
//                     {this.state.password.length < 7 ? (
//                       <div className="errormsg">
//                         Your password is not secure. <span>Reset password</span>
//                       </div>
//                     ) : (
//                       ""
//                     )}
//                     <div className="errormsg">{this.state.passwordError}</div>
//                     <span
//                       id="eye-icon"
//                       toggle="#password-field"
//                       className="fa fa-lg fa-fw fa-eye field-icon toggle-password"
//                       onClick={this.routeChange}
//                     ></span>
//                   </div>

//                   <button
//                     type="submit"
//                     id="signInButton"
//                     disabled={
//                       this.state.emailbutton || this.state.passwordbutton
//                     }
//                   >
//                     Sign In
//                   </button>
//                 </form>

//                 <div id="newFull" className="row">
//                   {" "}
//                   <hr className="loginhr" />
//                   <div id="new">New to IN-D ?</div>
//                   <hr className="loginhr" />{" "}
//                 </div>

//                 <NavLink to="/Register" style={{ textDecoration: "none" }}>
//                   <button id="createButton">Create your IN-D Account</button>
//                 </NavLink>
//               </div>
//             </div>
//           ) : (
//             <div className="ForgetClass jutify-content-center" id="loginForm">
//               <div className="ForgetHeader ">
//                 <img src={Back} onClick={this.BackToLogin} />
//                 <h4>Forgot your Password?</h4>
//                 <div className="forgetmsg">
//                   We will send you an email with a magic link. Click on the link
//                   to set new password.
//                   {/*
//                   We’ll help you reset it and get back on track.*/}
//                 </div>
//                 <form>
//                   <label id="name">Email Address</label>
//                   <input
//                     name="uname"
//                     onChange={this.handleUsernameChange}
//                     className="textInput"
//                     type="text"
//                     placeholder="you@example.com"
//                     size="50"
//                     required
//                   />
//                   <button
//                     type="submit"
//                     id="signInButton"
//                     onClick={this.ForgetPassword}
//                   >
//                     Send Magic Link
//                   </button>
//                 </form>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     );
//   }
// }

// const mapStateToProps = ({ userdetails }) => {
//   return {
//     passwordaging: userdetails.passwordaging,
//   };
// };
// const mapDispatchToProps = {
//   PasswordExpiry,
//   ForgetPassword,
// };
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withSnackbar(Login));

import React, { useState, useContext, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./login.css";
import IndLogo from "../../images/IND.png";
import { Button } from "@material-ui/core";
import { IND_ONE, PATH } from "../../Constants";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import Back from "../../images/back.svg";
import { withSnackbar } from "notistack";
import {
  PasswordExpiry,
  ForgetPassword,
  userLogin,
  GettingCount,
  UpdateCount,
  userLogout,
} from "../../Actions/userdetailsaction";
import { Link } from "react-router-dom";
function Login(props) {
  const dispatch = useDispatch();
  const [hidden, setHidden] = useState(true);
  const [loader, setLoader] = useState(false);
  const [userstate, setState] = useState({
    email: "",
    password: "",
  });
  const [forgetSwift, setForgetSwift] = useState(true);
  const [passwordError, setPasswordError] = useState("");
  const [notSecurePassword, setNotSecurePassword] = useState(false);
  useEffect(() => {
    document.body.style.zoom = "100%";
    // dispatch(userLogout());
  }, []);
  let user_token = localStorage.getItem("access");
  const handlePasswordChange = (e) => {
    setState({ ...userstate, password: e.target.value });
  };
  const EmailChange = (e) => {
    setState({ ...userstate, email: e.target.value });
  };
  const BackToLogin = () => {
    setForgetSwift(true);
    setState({});
  };
  const toggleShow = () => {
    setHidden(!hidden);
  };
  const LoginSubmission = async (e) => {
    e.preventDefault();
    setLoader(true);
    userLogin(userstate, props.history, (success, message) => {
      console.log(success, message);
      if (success) {
        setLoader(false);
        setPasswordError(message);
      } else {
        setLoader(false);
        // setIsLoggedIn(sessionStorage.getItem("token"));
        setPasswordError("");
        props.history.push("/");
        // window.location.href = "/";
      }
    });
  };
  // const LoginSubmission = async (e) => {
  //   e.preventDefault();
  //   setLoader(true);
  //   dispatch(
  //     userLogin(userstate, props.history, (success) => {
  //       if (!success) {
  //         setLoader(false);
  //       }
  //       if (success) {
  //         setLoader(false);
  //         // if (userstate.password.length < 8 && user_token) {
  //         //   setNotSecurePassword(true);
  //         // } else {
  //         //   setNotSecurePassword(false);
  //         // }
  //         dispatch(
  //           GettingCount(userstate, (response, success) => {
  //             if (success) {
  //               const time = Math.floor(response.difference);
  //               if (response.count === 0) {
  //                 dispatch(UpdateCount(userstate, response.count));
  //                 setPasswordError("Please enter the correct Password");
  //               } else if (response.count <= 3) {
  //                 dispatch(UpdateCount(userstate, response.count));
  //                 const attempts_left = 4 - response.count;
  //                 setPasswordError(
  //                   `Please enter the correct Password.Attempts Left:${attempts_left}`
  //                 );
  //               } else if (response.count == 4) {
  //                 setPasswordError(`Password is not Correct`);
  //               } else if (response.count >= 4) {
  //                 setPasswordError("");
  //                 if (time > 60) {
  //                   console.log("Greater than 60 min");
  //                   dispatch(UpdateCount(userstate, 0));
  //                 } else {
  //                   const message = `You have attempted wrong password for 3 times so Please try after ${
  //                     60 - time
  //                   } min`;
  //                   props.enqueueSnackbar(message, {
  //                     variant: "error",
  //                     autoHideDuration: 3000,
  //                   });
  //                 }
  //               }
  //             }
  //           })
  //         );
  //       }
  //     })
  //   );
  // };
  const ForgetChange = () => {
    setForgetSwift(false);
  };
  const handleForgetPassword = async (e) => {
    e.preventDefault();
    dispatch(
      ForgetPassword(userstate.email, (success) => {
        if (success) {
          props.history.push("/thankyou");
        }
      })
    );
  };
  return (
    <React.Fragment>
      <div className="Login_container">
        {forgetSwift ? (
          <div className="Login_form">
            <div className="Login_header">
              <img src={IndLogo} />
              <div
                style={{
                  color: "#3E3E3E",
                  fontFamily: "ProximaNovaR",
                  fontSize: "50px",
                  marginTop: "0.2rem",
                }}
              >
                Login
              </div>
            </div>
            <div>
              <form onSubmit={LoginSubmission}>
                <input
                  name="email"
                  onChange={EmailChange}
                  className="textInput"
                  placeholder="Email Address"
                  size="50"
                  required
                  value={userstate.email}
                  readOnly={notSecurePassword ? "readOnly" : ""}
                />
                <input
                  name="password"
                  onChange={handlePasswordChange}
                  className="textInput"
                  placeholder="Password"
                  size="50"
                  type={hidden ? "password" : "text"}
                  required
                  value={userstate.password}
                  readOnly={notSecurePassword ? "readOnly" : ""}
                />
                <div className="showPasswordContainer">
                  <div className="showPassword">
                    <input
                      type="checkbox"
                      onChange={(e) => setHidden(!hidden)}
                    />
                    <div>Show Password</div>
                  </div>
                  <a className="forgotPass" onClick={ForgetChange}>
                    Forgot Password?
                  </a>
                </div>
                {notSecurePassword ? (
                  <div className="error">
                    Your password is not secure. Reset password
                  </div>
                ) : (
                  ""
                )}
                <div className="errormsg">{passwordError}</div>
                <Button
                  disabled={loader}
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="submitbutton"
                >
                  {loader ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    ""
                  )}
                  {loader ? (
                    <div>&nbsp;Logging In...</div>
                  ) : notSecurePassword ? (
                    "Login Anyway"
                  ) : (
                    "Login"
                  )}
                </Button>
              </form>
            </div>
          </div>
        ) : (
          <div className="ForgetClass jutify-content-center" id="loginForm">
            <div className="ForgetHeader ">
              <img src={Back} onClick={BackToLogin} />
              <h4>Forgot your Password?</h4>
              <div className="forgetmsg">
                We will send you an email with a magic link. Click on the link
                to set new password.
                {/*
                We’ll help you reset it and get back on track.*/}
              </div>
              <form onSubmit={handleForgetPassword}>
                <label id="name">Email Address</label>
                <input
                  name="uname"
                  onChange={EmailChange}
                  className="textInput"
                  type="text"
                  style={{ width: "100%" }}
                  placeholder="you@example.com"
                  size="50"
                  required
                />
                <button type="submit" id="signInButton">
                  Send Magic Link
                </button>
              </form>
            </div>
          </div>
        )}
        <div className="footer">
          <a href="https://in-d.ai/" target="_blank">
            Company Website
          </a>
          <a href="https://in-d.ai/contact/" target="_blank">
            Contact us
          </a>
          <a href="https://in-d.ai/privacy-policy/" target="_blank">
            Privacy Policy
          </a>
        </div>
      </div>

      <div className="register">
        <p>
          Don't have an IN-D account?
          <Link to="/Register" style={{ textDecoration: "none" }}>
            Create Now
          </Link>
        </p>
      </div>
    </React.Fragment>
  );
}

export default withSnackbar(Login);
